import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { forwardRef, useCallback, useContext, useEffect, useMemo } from 'react';

import { useComponentWillMount } from '@webapp/common/hooks/legacy-lifecycle';
import { getColor, mapQuestionBlockPositionToAlignItems, setHtml } from '@webapp/common/lib/ui';
import type { ISurveyPageModel } from '@webapp/common/resources/mst-survey/page';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import { QuestionType } from '@webapp/common/resources/survey';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { RequiredMark } from '@webapp/ui/lib/icons';
import { CssPageBlock, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import { useStore } from 'store';

import { invalidMessageFactory, styleToQuestionPosition } from '../lib';
import css from '../question.css';

import { Comment } from './comment';
import { Header } from './header';
import { QuestionsContext } from './QuestionsContext';
import { TypeView } from './type-view';

const DevId: FC<{
    id: number;
}> = ({ id }) => <div style={{ float: 'right', position: 'absolute', left: '50%', color: 'red' }}>{id}</div>;

const Required: FC<{
    color: string;
    className?: string;
}> = ({ className, color }) => (
    <span className={cn(css.requiredMark, className)} style={{ color }}>
        <RequiredMark />
    </span>
);

// TODO move to ui/lib, merge with account
const HelpText: FC<{
    text: string;
}> = ({ text }) => {
    const { textStyle } = useTextStyle();

    return <div className={cn(CssPageBlock.QUESTION_HELP, css.help)} style={textStyle} {...setHtml(text)} />;
};

const InvalidMessage: FC<{
    msg: string;
}> = ({ msg }) => <div className={cn(CssUiComponent.INVALID, css.requiredText)}>{msg}</div>;

const CommentInvalidMessage: FC<{
    msg: string;
}> = ({ msg }) => <div className={cn(CssUiComponent.INVALID, css.commentInvalid)}>{msg}</div>;

const RequiredMessage: FC<{
    msg: string;
}> = ({ msg }) => <div className={cn(CssUiComponent.REQUIRED, css.requiredText)}>{msg}</div>;

export const QuestionView = observer(
    // eslint-disable-next-line react/display-name
    forwardRef<
        HTMLDivElement,
        {
            question: ISurveyQuestionModel;
            page: ISurveyPageModel;
            order?: number;
            readOnly?: boolean;
            showHeader?: boolean;
        }
    >((props, refSelf) => {
        const { page, question, readOnly, showHeader } = props;
        const { showError: showErrorStatus, noValidCounter } = useContext(QuestionsContext);

        const {
            commentInvalid,
            commentText,
            id,
            invalid,
            params: {
                comment,
                commentRequired,
                commentText: commentTextHelp,
                help,
                helpText,
                randomOrder,
                required,
                timer
            },
            survey: {
                info: {
                    design: {
                        fonts: { elementColor },
                        view: { questionBlockPosition }
                    },
                    params: {
                        alert,
                        other: { disallowTextCopy, showQuestionNumber, showRequiredToQuestion }
                    }
                }
            },
            type
        } = question;
        const {
            devTools: { enabled }
        } = useStore();

        const isCurrentQuestion = noValidCounter?.map((e) => Number(e.split('-')?.[0])).includes(id);
        const showError = showErrorStatus && isCurrentQuestion;
        const invalidMessage = invalid && invalidMessageFactory(question);
        const questionPositionClass = questionBlockPosition ? styleToQuestionPosition[questionBlockPosition] : '';
        const adjustedShowHeader = !(readOnly && type !== QuestionType.TEXT_BLOCK) || showHeader;
        const isCSI = type === QuestionType.CSI;

        useComponentWillMount(() => {
            if (!readOnly) {
                randomOrder && question.randomizeAnswersOrder();
                timer && question.startTimer();
            }
        });

        const showHelp = Boolean(help && helpText);
        const showCommentAlert = !readOnly && comment && commentRequired && commentInvalid;
        const showRequired = (!readOnly && invalid && required) || showError;
        const withError = invalid || commentInvalid || showError;
        const showRequiredMark = (required && showRequiredToQuestion) || showError;
        const showOrder = Boolean(showQuestionNumber && type !== QuestionType.TEXT_BLOCK && !page.random);
        const showCommentRequiredMark = (commentRequired && showRequiredToQuestion) || showError;
        const markColor = withError ? getColor('red') : elementColor;
        const mark = showRequiredMark ? <Required color={markColor} /> : null;
        const { itemsGap } = useTextStyle();

        const viewStyle: CSSProperties = useMemo(
            () => ({
                rowGap: itemsGap,
                alignItems: mapQuestionBlockPositionToAlignItems[questionBlockPosition]
            }),
            [itemsGap, questionBlockPosition]
        );

        useEffect(() => {
            if (isCSI && commentRequired) {
                question.setComment(' ');
            }
        }, []);

        const changeComment = useCallback(
            (v: string) => {
                question.setComment(v);
            },
            [question]
        );

        return (
            <div
                ref={refSelf}
                style={viewStyle}
                className={cn(
                    CssPageBlock.QUESTION,
                    `${CssPageBlock.QUESTION}_${id}`,
                    `${CssPageBlock.QUESTION}_type_${question.type}`,
                    css.question,
                    {
                        [CssUiComponent.REQUIRED]: showRequired,
                        [CssUiComponent.INVALID]: invalid,
                        [css.withError]: withError,
                        [css.noSelection]: disallowTextCopy,
                        [css.disabled]: readOnly
                    }
                )}
            >
                {adjustedShowHeader && (
                    <Header
                        mark={mark}
                        question={question}
                        readOnly={readOnly}
                        showOrder={showOrder}
                        showRequired={showRequiredMark}
                        withError={withError}
                    />
                )}
                {showRequired && <RequiredMessage msg={alert.required} />}
                {showError && <RequiredMessage msg={alert.yourAnswer} />}
                {showCommentAlert && <CommentInvalidMessage msg={alert.comment} />}
                {invalidMessage && <InvalidMessage msg={invalidMessage} />}
                {showHelp && <HelpText text={helpText} />}
                <div
                    className={cn(css.content, questionPositionClass, {
                        [css.readOnly]: readOnly
                    })}
                >
                    {enabled && <DevId id={id} />}
                    <TypeView align={questionBlockPosition} question={question} />
                </div>
                {comment && !isCSI && (
                    <Comment
                        commentInvalid={commentInvalid}
                        commentText={commentText}
                        commentTextHelp={commentTextHelp}
                        showMark={showCommentRequiredMark || showError}
                        markColor={commentInvalid ? getColor('red') : elementColor}
                        onChange={changeComment}
                    />
                )}
            </div>
        );
    })
);
QuestionView.displayName = 'QuestionView';
