import { useCallback, useEffect } from 'react';

export const useEsc = (cb: () => void): void => {
    const handleEsc = useCallback(
        ({ keyCode }) => {
            if (keyCode === 27) {
                cb && cb();
            }
        },
        [cb]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleEsc, false);
        return () => {
            document.removeEventListener('keydown', handleEsc, false);
        };
    }, [handleEsc]);
};
