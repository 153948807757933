// eslint-disable-next-line
require('@webapp/common/lib/sentry').default('survey');
require('@webapp/common/lib/wdyr');

import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';

import { useForceUpdate } from '@webapp/common/hooks/use-force-update';
import { FetchStatus } from '@webapp/common/lib/api';
import { isBrowser, isPreview } from '@webapp/common/lib/const';
import {
    clearSurveyCompleteCookie,
    clearSurveyTokenCookie,
    delCookieWithSubdomains,
    getCookie,
    getSurveyCompleteCookie,
    getSurveyTokenCookie
} from '@webapp/common/lib/cookies';
import { LocalStorage } from '@webapp/common/lib/storage';
import { isEmbedded } from '@webapp/common/lib/ua';
import { setHtml } from '@webapp/common/lib/ui';
// import { isOfflineMode } from '@webapp/common/resources/mst-survey/survey/lib';
import { CurrentSection, WelcomePageType } from '@webapp/common/resources/survey';
import { PublicResultsRoutes } from '@webapp/public-results/src/results-routes';
import { CustomStylesCtx, useViewCustomStyles } from '@webapp/ui/lib/custom-styles';
import type { CustomStylesConfig } from '@webapp/ui/lib/custom-styles';
import NotFound from '@webapp/ui/lib/not-found-page';

import { store, useStore } from '../store';

import { AuthView } from './auth';
import { handleWindowMessage, WindowCmd } from './embed';
import { SurveyHeader } from './view/header';
import { SurveyView } from './view/view';

import 'iframe-resizer/js/iframeResizer.contentWindow.min';

if (isPreview) {
    // reset all settings on page reload
    LocalStorage.clear();
    clearSurveyCompleteCookie();
} else if (getCookie('preview_opened')) {
    clearSurveyTokenCookie();
    delCookieWithSubdomains('preview_opened');
}

const setMobileViewport = (): void => {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const vh = window.innerHeight * 0.01;
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
};

if (isBrowser) {
    setMobileViewport();
    window.addEventListener('resize', setMobileViewport);
}

const init = async (): Promise<boolean> => {
    // TODO merge with initializeStore and store actions
    const token = getSurveyTokenCookie();
    const host = window.location.host;
    const domain = host.split('.')[0];
    const individualTries = LocalStorage.get<number>(`${domain}-invalid-password`) || 0;

    store.survey.setDomain(domain);
    store.survey.setToken(token);
    await store.survey.setIndividualTries(individualTries);
    await store.survey.init();

    if (!store.survey.token || store.survey.initStatus === FetchStatus.ERROR) {
        clearSurveyTokenCookie();
        return false;
    }

    return true;
};

const SurveyPage = observer(() => {
    const { survey } = useStore();
    const [pageHeight, setPageHeight] = useState();
    const forceUpdate = useForceUpdate();
    const { currentSection, info } = survey;
    const {
        design,
        params: {
            main: { disabledSurveyText, surveyWasBlocked },
            other: { disallowRefilling }
        }
    } = info;
    const { elementColor } = design.fonts;
    const customStyles: Partial<CustomStylesConfig> = useViewCustomStyles(design);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateSurvey = useCallback(
        debounce(
            1000,
            async () => {
                const success = await init();
                if (!success) return;

                const withWelcomePage = survey.welcomePage === WelcomePageType.TO_SINGLE_PAGE;
                const started = survey.currentSection !== CurrentSection.START && survey.started;

                if (survey.currentSection === CurrentSection.DISABLED) {
                    return; // TODO refactor, move all logic inside model
                }

                const complete = getSurveyCompleteCookie();
                if (disallowRefilling) {
                    return;
                }

                if (!complete && survey.currentSection !== CurrentSection.AUTH) {
                    if (started) {
                        await survey.start(true);
                    } else if (!withWelcomePage) {
                        await survey.start(false);
                    }
                }
                forceUpdate();
            },
            { atBegin: true }
        ),
        []
    );

    useEffect(() => {
        if (isPreview || isEmbedded) {
            const handler = handleWindowMessage({
                [WindowCmd.UpdateSurvey]: updateSurvey,
                [WindowCmd.SetParentHeight]: setPageHeight
            });
            window.addEventListener('message', handler);
            return () => window.removeEventListener('message', handler);
        }
    }, [updateSurvey]);

    useEffect(() => {
        updateSurvey();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const notifyClick = (): void => {
            window.parent.postMessage('previewClick', '*');
        };

        if (isPreview) {
            document.addEventListener('click', notifyClick);
            return () => document.removeEventListener('click', notifyClick);
        }
    }, []);

    useEffect(() => {
        if (isEmbedded && isBrowser) {
            const html = document.getElementsByTagName('html')[0];
            html.classList.add('embedded');
        }
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty('--tt-elementColor', elementColor);
    }, [elementColor]);

    useEffect(() => {
        if (isBrowser && pageHeight) {
            const html = document.getElementsByTagName('html')[0];
            const body = document.body;
            html.style.overflow = 'hidden';
            body.style.height = `${pageHeight}px`;
            body.style.overflow = 'auto';
        }
    }, [pageHeight]);

    if (survey.infoFetchingStatus === FetchStatus.ERROR || survey.infoFetchingStatus === FetchStatus.NOT_FOUND) {
        return <NotFound button={false} />;
    }

    if (currentSection === CurrentSection.AUTH) {
        return <AuthView survey={survey} />;
    }

    if (currentSection === CurrentSection.RESULTS) {
        PublicResultsRoutes.results.assign();
        return null;
    }

    if (!info) {
        return null;
    }

    const {
        question: { text: questionTextStyles }
    } = customStyles;

    if (currentSection === CurrentSection.DISABLED) {
        return (
            <CustomStylesCtx.Provider value={customStyles}>
                <SurveyHeader info={info} />
                <div {...setHtml(disabledSurveyText)} style={questionTextStyles} />
            </CustomStylesCtx.Provider>
        );
    }

    if (currentSection === CurrentSection.BLOCKED) {
        return (
            <CustomStylesCtx.Provider value={customStyles}>
                <SurveyHeader info={info} />
                <div {...setHtml(surveyWasBlocked)} style={questionTextStyles} />
            </CustomStylesCtx.Provider>
        );
    }

    return (
        <CustomStylesCtx.Provider value={customStyles}>
            <SurveyView />
            <div id='popper' />
            {/* <PrivacyPopup /> */}
        </CustomStylesCtx.Provider>
    );
});

export const SurveyPageWrap: FC<{
    error?: any;
}> = ({ error }) => {
    if (error) {
        return <NotFound button={false} />;
    }

    return <SurveyPage />;
};
