import cn from 'classnames';
import type { CSSProperties, FC } from 'react';

import { setHtml } from '@webapp/common/lib/ui';
import { stringNotEmpty } from '@webapp/common/lib/utils';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { Scrollarea } from '@webapp/ui/lib/scrollarea';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import css from './left-right.css';

const Label: FC<{
    style: CSSProperties;
    value: any;
}> = ({ style, value }) => {
    if (!stringNotEmpty(value)) return null;
    return <div className={cn(CssUiComponent.LABEL, css.label)} style={style} {...setHtml(value)} />;
};

export const LeftRight: FC<{
    left: string;
    right: string;
    className: string;
}> = ({ children, className, left, right }) => {
    const { textStyle: labelStyle } = useTextStyle();

    return (
        <div className={cn(CssQuestionType.QUESTION, css.leftRight, className)}>
            <Scrollarea vertical={false}>
                <div className={cn(CssUiComponent.LABEL, css.labels)}>
                    <Label style={labelStyle} value={left} />
                    <Label style={labelStyle} value={right} />
                </div>
                <div className={cn(CssPageBlock.ANSWER, css.content)}>{children}</div>
            </Scrollarea>
        </div>
    );
};
