import { observer } from 'mobx-react-lite';
import type { FC } from 'react';

import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';

import { MatrixLayout } from '../matrix-layout';

import { MatrixTextGroup } from './group';

export const MatrixText: FC<SurveyQuestion> = observer(({ question }) => (
    <MatrixLayout cell={MatrixTextGroup} className={CssQuestionType.MATRIX_TEXT} question={question} />
));
MatrixText.displayName = 'MatrixAnswerText';
