import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import cn from 'classnames';
import { Children, forwardRef } from 'react';

import { Drag } from '../icons';

import css from './sortable.css';

export const SortableHandler = forwardRef<
    HTMLDivElement,
    {
        children?: ReactNode;
        className?: string;
        iconStyle?: CSSProperties;
        extraStyle?: CSSProperties;
        extraClassName?: string;
        listeners?: DraggableSyntheticListeners;
    }
>(({ children, className, extraClassName, extraStyle, iconStyle, listeners }, ref) => (
    <div className={cn(css.handler, { [css.useHandle]: ref }, className)}>
        <div className={css.handle} ref={ref}>
            <Drag className={css.drag} style={iconStyle} {...listeners} />
        </div>
        {Children.count(children) > 0 ? (
            <p className={cn(css.extra, extraClassName)} style={extraStyle}>
                {children}
            </p>
        ) : null}
    </div>
));
SortableHandler.displayName = 'SortableHandler2';
