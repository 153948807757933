import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { memo, useCallback, useMemo } from 'react';
import type { FC } from 'react';

import { useToggle } from '@webapp/common/hooks/use-toggle';
import { setHtml, stopEvent } from '@webapp/common/lib/ui';
import { AgreementModal } from '@webapp/survey/src/components/questions/components/modal';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { Checkbox } from '@webapp/ui/lib/checkbox';
import { Scrollarea } from '@webapp/ui/lib/scrollarea';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import css from './agreement.css';

const style = { maxWidth: '100%' };

const Label: FC<{
    agreementLeftLabel: string;
    agreementLink: string;
    agreementRightLabel: string;
    onToggle: (e: any) => void;
}> = ({ agreementLeftLabel, agreementLink, agreementRightLabel, onToggle }) => (
    <span className={CssUiComponent.LABEL}>
        <span {...setHtml(agreementLeftLabel)} />{' '}
        <a className={css.agreementLink} onClick={onToggle} {...setHtml(agreementLink)} />{' '}
        <span {...setHtml(agreementRightLabel)} />
    </span>
);

const Popup: FC<{
    agreementText: string;
    onClose: () => void;
}> = memo(({ agreementText, onClose }) => (
    <AgreementModal onClose={onClose}>
        <Scrollarea horizontal={false}>
            <div className={CssPageBlock.ANSWER} {...setHtml(agreementText)} />
        </Scrollarea>
    </AgreementModal>
));
Popup.displayName = 'Popup';

export const Agreement: FC<SurveyQuestion> = observer(
    ({
        question: {
            params: { agreementLeftLabel, agreementLink, agreementRightLabel, agreementText },
            response: { change, value }
        }
    }) => {
        const { enabled: modalVisible, off: close, toggle } = useToggle(false);
        const togglePopup = useCallback(
            (e) => {
                stopEvent(e);
                toggle();
            },
            [toggle]
        );

        const label = useMemo(
            () => (
                <Label
                    agreementLeftLabel={agreementLeftLabel}
                    agreementLink={agreementLink}
                    agreementRightLabel={agreementRightLabel}
                    onToggle={togglePopup}
                />
            ),
            [agreementLeftLabel, agreementLink, agreementRightLabel, togglePopup]
        );

        const onChange = useCallback((v: boolean) => void change(v), [change]);

        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.AGREEMENT)} style={style}>
                {modalVisible && <Popup agreementText={agreementText} onClose={close} />}
                <Checkbox checked={value as boolean} label={label} onChange={onChange} />
            </div>
        );
    }
);
Agreement.displayName = 'Agreement';
