import { HOST_MAIN, HOST_PROTOCOL } from '@webapp/common/conf';
import { Button } from '@webapp/ui/lib/button';
import { NotFound } from '@webapp/ui/lib/icons';

import css from './not-found-page.css';

const handleOnGoHome = (): void => {
    location.href = HOST_PROTOCOL + HOST_MAIN;
};

export const NotFoundPage: FC<{
    button?: boolean;
}> = ({ button = true }) => (
    <>
        <NotFound className={css.image} />
        <h2 className={css.head}>Такой страницы не существует</h2>
        {button && (
            <div className={css.buttonWrap}>
                <Button className={css.button} color='blue' onClick={handleOnGoHome}>
                    На главную
                </Button>
            </div>
        )}
    </>
);
