import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import { AnswerType } from '@webapp/common/resources/survey';
import { Checkbox } from '@webapp/ui/lib/checkbox';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { AnswerIcon } from '@webapp/ui/lib/misc';
import { CssPageBlock, CssUiComponent } from '@webapp/ui/lib/survey-custom';
import { Textfield } from '@webapp/ui/lib/textfield';

import { QuestionsContext } from 'components/questions/components/QuestionsContext';

import css from './list-select.css';

export const ListSelectAnswer = observer<{
    answer: ISurveyQuestionAnswerModel;
    isTestCheckedQuestion: boolean;
    showResults: boolean;
    multiple: boolean;
    questionId: number;
    required: boolean;
}>((props) => {
    const {
        required,
        answer: {
            id,
            response: { change, changeExtra, extra, value: responseValue },
            testCheckStatus,
            type,
            value: [label]
        },
        isTestCheckedQuestion,
        multiple,
        showResults,
        questionId
    } = props;
    const self = type === AnswerType.SELF;
    const checked = responseValue as boolean;
    const inputRef = useRef(null);
    const { itemsGap } = useTextStyle();
    const { noValidCounter, setNoValidCounter, setShowError } = useContext(QuestionsContext);
    const noValidAnswers = noValidCounter.map((e) => e.split('-')?.[1]);

    // required
    useEffect(() => {
        if (self && required) {
            if (checked && !extra && !noValidAnswers?.includes(String(id))) {
                setNoValidCounter((prev) => [...prev, `${questionId}-${id}`]);
            } else if (
                (!checked && noValidAnswers?.includes(String(id))) ||
                (extra && noValidAnswers?.includes(String(id)))
            ) {
                setShowError(false);
                setNoValidCounter((prev) => prev.filter((e) => e?.split('-')?.[1] !== String(id)));
            }
        }
    }, [checked, noValidCounter, extra]);

    const style = useMemo(
        () =>
            self
                ? {
                      rowGap: itemsGap
                  }
                : {},
        [itemsGap, self]
    );

    const changeSelf = useCallback(
        (v: any): void => {
            void changeExtra(v);
        },
        [changeExtra]
    );

    const toggle = useCallback(() => {
        const checked = !responseValue;
        void change(checked);
        if (checked && inputRef.current) {
            inputRef.current.focus();
        }
    }, [change, responseValue]);

    const checkOnInputClick = useCallback(() => {
        void change(true);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [change]);

    return useMemo(
        () => (
            <div className={cn(CssPageBlock.ANSWER, css.answer, self && css.self)} key={id} style={style}>
                <div className={cn(CssUiComponent.CHECKBOX, css.checkbox)} onClick={toggle}>
                    {showResults && isTestCheckedQuestion && (
                        <AnswerIcon checked={checked} testCheckStatus={testCheckStatus} />
                    )}
                    <Checkbox checked={checked} label={label} round={!multiple} />
                </div>
                {self && (
                    <Textfield
                        transparent
                        autoFocus={!!responseValue}
                        ref={inputRef}
                        rootClassName={css.text}
                        value={(extra as string) || ''}
                        onChange={changeSelf}
                        onClick={checkOnInputClick}
                    />
                )}
            </div>
        ),
        [
            self,
            id,
            style,
            toggle,
            showResults,
            isTestCheckedQuestion,
            checked,
            testCheckStatus,
            label,
            multiple,
            responseValue,
            extra,
            changeSelf,
            checkOnInputClick
        ]
    );
});
