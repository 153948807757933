import { getSnapshot } from 'mobx-state-tree';

import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import { getApiClass, QuestionType } from '@webapp/common/resources/survey';

import css from './question.css';

const replaceMinMax = (tpl: string, min: number, max: number): string =>
    tpl.replace(/{min}/, String(min)).replace(/{max}/, String(max));

const replacePoints = (tpl: string, points: number): string => tpl.replace(/{points}/, String(points));

export const invalidMessageFactory = (question: ISurveyQuestionModel): string => {
    const {
        answeredCount,
        answers,
        groups,
        invalid,
        params: { distributeScore, distributeSurplus, maxLength: max, minLength: min },
        survey,
        type
    } = question;
    const { amount, distribution, matrixAmount, numRange } = survey.info.params.alert;
    const withMax = max !== null;
    const withMin = min !== null;

    switch (type) {
        case QuestionType.SELECT_FEW_IMAGE: {
            if ((withMax && answeredCount > max) || (withMin && answeredCount < min)) {
                return replaceMinMax(amount, min, max);
            }

            return null;
        }
        case QuestionType.DISTRIBUTE_SCALE: {
            const pointsSum = answers.reduce((acc, { response: { value } }) => acc + (value as number), 0);
            const points = parseInt(`${distributeScore}`);

            if (pointsSum !== points && invalid && distributeSurplus) {
                return replacePoints(distribution, points);
            }
            return null;
        }
        case QuestionType.MATRIX_SINGLE_ANSWER: {
            const answersNumber = groups.reduce(
                (acc, group) =>
                    acc +
                    Object.keys(getSnapshot(group.responses)).reduce(
                        (acc, key) => acc + (group.responses.get(key).response.value ? 1 : 0),
                        0
                    ),
                0
            );
            if ((withMin && answersNumber < min) || (withMax && answersNumber > max)) {
                return replaceMinMax(matrixAmount, min, max);
            }
            return null;
        }
        case QuestionType.TEST_FEW_OF_LIST:
        case QuestionType.FEW_OF_LIST: {
            if (
                (withMin && answers.every((a) => a.answeredCount >= min)) ||
                (withMax && answers.every((a) => a.answeredCount <= max))
            ) {
                return replaceMinMax(amount, min, max);
            }

            return null;
        }
        case QuestionType.MATRIX_FEW_ANSWERS: {
            if (
                (withMin && answers.every((a) => a.answeredCount >= min)) ||
                (withMax && answers.every((a) => a.answeredCount <= max))
            ) {
                return replaceMinMax(matrixAmount, min, max);
            }

            return null;
        }
        case QuestionType.NUMBER: {
            const api = getApiClass(QuestionType.NUMBER);

            if (!api.validate(question) && (withMin || withMax)) {
                return replaceMinMax(numRange, min, max);
            }

            return null;
        }
        default:
            return null;
    }
};

export const styleToQuestionPosition = {
    right: css.end,
    left: css.start,
    center: css.center
};

export interface SurveyQuestion {
    question: ISurveyQuestionModel;
    align?: AlignSetting;
}
