import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';

import { useMobile } from '@webapp/common/hooks/use-mobile';
import { setHtml } from '@webapp/common/lib/ui';
import type { ISurveyQuestionGroupModel } from '@webapp/common/resources/mst-survey/question_group';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CheckboxDumb } from '@webapp/ui/lib/checkbox-dumb';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { GradientNumbers } from '@webapp/ui/lib/gradient-numbers';
import { NumbersWithBorders } from '@webapp/ui/lib/numbers-with-borders';
import { Slider } from '@webapp/ui/lib/slider';
import { CssPageBlock, CssQuestionType, CssUiComponent, PLACEHOLDER } from '@webapp/ui/lib/survey-custom';

import { CANTRATE_ID } from 'components/questions/constants';

import { LeftRight } from '../left-right';

import css from './scale.css';

const Groups = observer<{
    onClick: (value: number) => () => void;
    groups: Array<ISurveyQuestionGroupModel>;
    regularStyle: CSSProperties;
    selectedStyle: CSSProperties;
    response: ISurveyQuestionResponseModel;
}>(({ groups, onClick, regularStyle, response, selectedStyle }) => (
    <div className={cn(CssPageBlock.ANSWER, css.field)}>
        {groups.map((group) => {
            const { id, name } = group;
            const isSelected = response.value === group.id;

            return (
                <div
                    className={cn(CssUiComponent.LABEL, css.item, { [css.selected]: isSelected })}
                    key={id}
                    style={isSelected ? selectedStyle : regularStyle}
                    onClick={onClick(id)}
                    {...setHtml(name)}
                />
            );
        })}
    </div>
));

export const Scale: FC<SurveyQuestion> = observer(({ question: { answers, response, groups, ...rest } }) => {
    const [
        {
            value: [leftLabel, , rightLabel]
        }
    ] = answers;

    const {
        params: {
            cantRate,
            cantRateText,
            scaleColors,
            scaleEndDia,
            scaleStartDia,
            scaleType,
            scaleFormat,
            npsLeftLabel,
            npsRightLabel,
            size
        }
    } = rest;

    const isMobile = useMobile();
    const { answerStyle, textStyle } = useTextStyle();
    const fontSize = isMobile ? `clamp(16px, calc(10px + 1.5vw), 16px)` : textStyle.fontSize;

    const selectedStyle: CSSProperties = useMemo(
        () => ({
            ...textStyle,
            boxShadow: `inset 0px 0px 0px 2px ${answerStyle.color}`,
            fontSize
        }),
        [textStyle, answerStyle.color, fontSize]
    );

    const regularStyle = useMemo(
        () => ({
            ...textStyle,
            fontSize
        }),
        [fontSize, textStyle]
    );

    const isCheckedInit = response?.value === CANTRATE_ID;

    const [cantRateChecked, setСantRateChecked] = useState(isCheckedInit);

    const handleChecked = useCallback(() => {
        setСantRateChecked(true);
        response.change(CANTRATE_ID);
    }, [response]);

    const handleSelect = useCallback(
        (value: number) => () => {
            setСantRateChecked(false);
            response.change(value);
            if (scaleType === 'number') {
                response.setValue(value);
            }
        },
        [response]
    );

    const selected = useMemo(() => groups.find((group) => group.id === response.value), [response.value, groups]);

    const renderContent = () => {
        if (scaleType !== 'number') {
            return (
                <LeftRight className={CssQuestionType.SCALE} left={leftLabel as string} right={rightLabel as string}>
                    <Groups
                        groups={groups}
                        regularStyle={regularStyle}
                        response={response}
                        selectedStyle={selectedStyle}
                        onClick={handleSelect}
                    />
                </LeftRight>
            );
        }
        if (scaleFormat === 'numbers') {
            return (
                <LeftRight
                    className={cn(CssQuestionType.SCALE, css.leftRight)}
                    left={npsLeftLabel}
                    right={npsRightLabel}
                >
                    <GradientNumbers
                        selectedValue={selected?.numericValue}
                        colors={scaleColors}
                        itemsCount={scaleEndDia - scaleStartDia + 1}
                        startsWith={scaleStartDia}
                        size={size === 1 ? 'small' : 'normal'}
                        onClick={(value) => handleSelect(groups.find((group) => group.numericValue === value)?.id)()}
                    />
                </LeftRight>
            );
        }
        if (scaleFormat === 'numbers-with-borders') {
            return (
                <LeftRight
                    className={cn(CssQuestionType.SCALE, css.leftRight, css.leftRightNumbersWithBorder)}
                    left={npsLeftLabel}
                    right={npsRightLabel}
                >
                    <NumbersWithBorders
                        selectedValue={selected?.numericValue}
                        colors={scaleColors}
                        itemsCount={scaleEndDia - scaleStartDia + 1}
                        startsWith={scaleStartDia}
                        onClick={(value) => handleSelect(groups.find((group) => group.numericValue === value)?.id)()}
                    />
                </LeftRight>
            );
        }
        if (scaleFormat === 'slider') {
            return (
                <LeftRight
                    className={cn(CssQuestionType.SCALE, css.leftRight, css.leftRightSlider)}
                    left={npsLeftLabel}
                    right={npsRightLabel}
                >
                    <Slider
                        defaultValue={selected?.numericValue ?? null}
                        min={scaleStartDia}
                        max={scaleEndDia}
                        value={selected?.numericValue ?? null}
                        withoutControl
                        labelsBottom
                        handlerColor={scaleColors[0]}
                        trackBgImage={`linear-gradient(90deg, ${scaleColors.join(', ')})`}
                        onChange={(value) => handleSelect(groups.find((group) => group.numericValue === value)?.id)()}
                    />
                </LeftRight>
            );
        }
    };

    return (
        <div
            className={cn(
                css.container,
                'question__container',
                scaleType === 'number' && (scaleEndDia > 3 || scaleFormat === 'slider') && css.scaleTypeNumber,
                scaleType === 'number' && scaleFormat !== 'slider' && scaleEndDia < 4 && css.scaleTypeNumberLessThanFour
            )}
        >
            {renderContent()}
            {cantRate && (
                <div>
                    <CheckboxDumb
                        checked={cantRateChecked}
                        className={css.cantRateCheckbox}
                        label={cantRateText || PLACEHOLDER.CANT_RATE}
                        round={false}
                        onChange={handleChecked}
                    />
                </div>
            )}
        </div>
    );
});
