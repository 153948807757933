import { observer } from 'mobx-react-lite';
import type { FC } from 'react';

import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';
import { Textfield } from '@webapp/ui/lib/textfield';

const style = { width: '100%' };

export const ShortText: FC<SurveyQuestion> = observer(
    ({
        question: {
            response: { change, value }
        }
    }) => {
        const { inputStyle } = useTextStyle();
        return (
            <div className={CssQuestionType.SHORT_TEXT} style={style}>
                <Textfield
                    transparent
                    style={inputStyle}
                    value={(value as string) || ''}
                    onChange={(v: any) => void change(v)}
                />
            </div>
        );
    }
);
ShortText.displayName = 'ShortText';
