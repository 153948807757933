import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useRef } from 'react';

import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import type { ISurveyQuestionGroupModel } from '@webapp/common/resources/mst-survey/question_group';
import { AnswerType, QuestionType } from '@webapp/common/resources/survey';
import { Checkbox } from '@webapp/ui/lib/checkbox';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { CssUiComponent } from '@webapp/ui/lib/survey-custom';
import { Textfield } from '@webapp/ui/lib/textfield';

import type { MatrixCell } from '../matrix-layout/matrix-cell';
import cssLayout from '../matrix-layout/matrix-layout.css';

import css from './matrix-select.css';

export const GroupSelect = observer<MatrixCell>(({ answer, group, height, question }) => {
    const multiple = question.type !== QuestionType.MATRIX_FEW_ANSWERS;
    const { id, name, type } = group;
    const self = type === AnswerType.SELF;
    const response = group.getResponse(answer.id);
    const checked = Boolean(response?.value);
    const textRef = useRef(null);
    const value = String(response?.extra || '');
    const onChange = useCallback(
        ({ id }: ISurveyQuestionAnswerModel, group: ISurveyQuestionGroupModel) =>
            (value: boolean) =>
                group.changeResponseValue(id, value),
        []
    );

    const onChangeExtra = useCallback(
        (value: string) => group.changeResponseExtra(answer.id, value),
        [answer.id, group]
    );

    const { answerStyle, itemsGap } = useTextStyle();

    const checkboxStyle = useMemo(
        // TODO use checkbox styles
        () => ({
            border: `2px solid ${answerStyle.color}`,
            backgroundColor: checked ? answerStyle.color : undefined
        }),
        [checked, answerStyle.color]
    );

    const fieldStyle = useMemo(() => ({ rowGap: itemsGap }), [itemsGap]);
    const cellStyle = useMemo(() => ({ height }), [height]);

    const onCheck = useCallback(
        (value) => {
            void onChange(answer, group)(value);
            const input = textRef.current;
            if (input && self && value) {
                input.focus();
            }
        },
        [answer, group, onChange, self]
    );

    const onClickSelf = useCallback(() => {
        void onChange(answer, group)(true);
    }, [answer, group, onChange]);

    return (
        <div className={cn(CssUiComponent.CELL, cssLayout.cell, css.answer)} key={id} style={cellStyle}>
            <div className={cn(css.field, self && css.self)} style={fieldStyle}>
                <Checkbox
                    checked={checked}
                    label={name}
                    labelClassName={css.label}
                    round={multiple}
                    style={checkboxStyle}
                    onChange={onCheck}
                />
                {self && (
                    <Textfield
                        transparent
                        className={cssLayout.control}
                        ref={textRef}
                        rootClassName={css.text}
                        value={value}
                        onChange={onChangeExtra}
                        onClick={onClickSelf}
                    />
                )}
            </div>
        </div>
    );
});
GroupSelect.displayName = 'Group';
