import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useMemo, useState } from 'react';

import { API_URL } from '@webapp/common/conf';
import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import { QuestionType } from '@webapp/common/resources/survey';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CustomStylesCtx, useTextStyle } from '@webapp/ui/lib/custom-styles';
import { ImageSlider } from '@webapp/ui/lib/image-slider';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';

import { Image } from './image';

import css from './select-image.css';

export const SelectImage: FC<SurveyQuestion> = observer(
    ({
        question: {
            answers,
            params: { imageColumns: columns, imageTextPosition: textPosition, size },
            type
        }
    }) => {
        const [modalOpen, setModalOpen] = useState<boolean | number>(false);
        const { itemsGap } = useTextStyle();
        const { selectImage } = useContext(CustomStylesCtx);
        const multiple = type === QuestionType.SELECT_FEW_IMAGE;
        const initialImg = typeof modalOpen === 'number' ? modalOpen : 0;
        const slides = useMemo(
            () =>
                answers.map(({ file, id, response: { value } }) => ({
                    id,
                    img: {
                        url: API_URL + file?.path,
                        width: file?.width,
                        height: file?.height
                    },
                    checked: value as boolean
                })),
            [answers]
        );

        const imagesWrapStyle: CSSProperties = useMemo(() => {
            const layout = Array(columns).fill('1fr').join(' ');
            return {
                width: `${size}%`,
                gridTemplateColumns: layout,
                gap: itemsGap
            };
        }, [columns, itemsGap, size]);

        const commonAspect: number = useMemo(() => {
            const aspects = [];
            answers.forEach(({ file }) => {
                const { height, width } = file || { width: 0, height: 0 };
                aspects.push(width / height);
            });

            return aspects.reduce((sum, a) => sum + a, 0) / aspects.length;
        }, [answers]);

        const toggleSelect = useCallback((answer: ISurveyQuestionAnswerModel) => {
            void answer?.response.change(!answer?.response.value);
        }, []);

        const handleClickImage = useCallback(
            (answer: ISurveyQuestionAnswerModel) => (e) => {
                e.preventDefault();

                return toggleSelect(answer);
            },
            [toggleSelect]
        );

        const handleSelect = useCallback(
            (id: number, checked: boolean) => {
                const answer = answers.find(({ id: answerId }) => answerId === id);
                void answer?.response?.change?.(checked);
            },
            [answers]
        );

        const handleZoomImg = useCallback(
            (idx: number) => (event: ReactMouseEvent<SVGSVGElement, MouseEvent>) => {
                event.stopPropagation();
                event.preventDefault();
                setModalOpen(idx);
            },
            []
        );

        const closeSlider = useCallback(() => setModalOpen(false), []);

        return (
            <>
                {modalOpen !== false && (
                    <ImageSlider
                        initialImg={initialImg}
                        multiple={multiple}
                        slides={slides}
                        onClose={closeSlider}
                        onSelect={handleSelect}
                    />
                )}
                <div
                    style={imagesWrapStyle}
                    className={cn(CssQuestionType.QUESTION, CssQuestionType.IMAGE_SELECT, css.list, {
                        [css.col1]: columns === 1,
                        [css.col2]: columns === 2,
                        [css.col3]: columns === 3
                    })}
                >
                    {answers.map((answer, idx) => (
                        <Image
                            answer={answer}
                            aspect={commonAspect}
                            gap={itemsGap}
                            index={idx}
                            key={answer.id}
                            selectImage={selectImage}
                            textPosition={textPosition}
                            type={type}
                            onClick={handleClickImage(answer)}
                            onClickZoom={handleZoomImg}
                        />
                    ))}
                </div>
            </>
        );
    }
);
SelectImage.displayName = 'SelectImage';
