import ScrollTo from 'animated-scroll-to';
import { observer } from 'mobx-react-lite';
import { useLayoutEffect, useMemo, useRef } from 'react';

import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';

import { QuestionView } from './components';

import css from './questions.css';

const NoQuestions: FC = () => <div>Нет вопросов</div>;

export const QuestionsSection = observer<{
    questions: Array<ISurveyQuestionModel>;
    survey: any;
    scrollToQuestionId?: number;
    resetScroll?: () => void;
    readOnly?: boolean;
    showHeader?: boolean;
}>(({ questions, readOnly, resetScroll, scrollToQuestionId, showHeader, survey }) => {
    const firstInvalidRef = useRef<HTMLDivElement>();
    const { questionGap } = useTextStyle();
    const style = useMemo(
        () => ({
            rowGap: questionGap,
            marginBottom: questionGap
        }),
        [questionGap]
    );

    const questionsElements = useMemo(
        () =>
            questions.map((q) => {
                const scrollToSelf = scrollToQuestionId === q.id;
                const ref = scrollToSelf ? firstInvalidRef : null;
                const page = survey.pages.find((p) => p.id === q.page);

                return (
                    <QuestionView
                        key={q.id}
                        page={page}
                        question={q}
                        readOnly={readOnly}
                        ref={ref}
                        showHeader={showHeader}
                    />
                );
            }),
        [questions, readOnly, scrollToQuestionId, showHeader, survey.pages]
    );

    useLayoutEffect(() => {
        let timer;
        if (scrollToQuestionId) {
            timer = setTimeout(() => {
                const { current } = firstInvalidRef;
                if (current) {
                    parent && parent.postMessage(`scrollTo::${JSON.stringify(current.getBoundingClientRect())}`, '*');
                    void ScrollTo(current, {
                        speed: 1000
                    }).then(() => {
                        resetScroll && resetScroll();
                    });
                }
            }, 500);
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToQuestionId, firstInvalidRef.current]);

    useLayoutEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <div className={css.questions} style={style}>
            {questions.length > 0 ? questionsElements : <NoQuestions />}
        </div>
    );
});
QuestionsSection.displayName = 'QuestionsSection';
