import cn from 'classnames';
import { memo } from 'react';

import css from './date-picker.css';

export const Groups: FC<{
    groups: Array<Array<number>>;
    onSelect: (num: number) => void;
    renderCell: (num: number) => string;
}> = memo(
    ({ groups, onSelect, renderCell }): JSX.Element => (
        <div
            className={cn({
                ['react-datepicker__month']: true,
                [css.yearmonth]: true
            })}
        >
            {groups.map((group, itemIndex) => (
                <div
                    key={itemIndex}
                    className={cn({
                        ['react-datepicker__week']: true,
                        [css.row]: true
                    })}
                >
                    {group.map((num, index) => (
                        <div
                            key={index}
                            className={cn({
                                ['react-datepicker__day']: true,
                                [css.cell]: true
                            })}
                            onClick={() => onSelect(num)}
                        >
                            {renderCell(num)}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
);
Groups.displayName = 'Groups';
