import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import type { CSSProperties, FC } from 'react';
import { useMemo } from 'react';

import { createTextStyle, styleStrToObject } from '@webapp/common/lib/ui';
import type { ISurveyInfo } from '@webapp/common/resources/mst-survey/info';
import { useBodyTitleStyle } from '@webapp/ui/lib/custom-styles';
import { CssPageBlock } from '@webapp/ui/lib/survey-custom';

import { SurveySection } from '../container';

import css from './survey-title.css';

export const SurveyTitle: FC<{
    info: ISurveyInfo;
    inBody?: boolean;
    empty?: boolean;
    headerHeight?: number;
}> = observer(({ empty, headerHeight, inBody, info: { design, title } }) => {
    const {
        brand: { titleStyle },
        fonts: { titleBold, titleColor, titleItalic, titleSize, titleThrough, titleUnderline }
    } = design;

    const titlePositionStyles = useMemo(() => styleStrToObject(titleStyle), [titleStyle]);
    const textStyle = createTextStyle({
        underline: titleUnderline,
        through: titleThrough,
        bold: titleBold,
        italic: titleItalic,
        color: titleColor,
        size: titleSize
    });

    const titleStyles: CSSProperties = useMemo(
        () => ({
            ...textStyle,
            ...(inBody ? {} : titlePositionStyles),
            ['--mobile-height' as any]: `${headerHeight ? headerHeight / 2 : 0}px`
        }),
        [headerHeight, inBody, textStyle, titlePositionStyles]
    );

    const bodyTitleStyle = useBodyTitleStyle(design);
    const bodyStyle = useMemo(
        () => ({
            ...bodyTitleStyle,
            ...textStyle
        }),
        [bodyTitleStyle, textStyle]
    );

    return inBody ? (
        <div className={cn(CssPageBlock.HEADER_TITLE, !inBody && css.title)} style={bodyStyle}>
            {title}
        </div>
    ) : (
        <SurveySection className={cn(CssPageBlock.HEADER_TITLE_CONTAINER, css.container)}>
            {!empty && (
                <div className={cn(CssPageBlock.HEADER_TITLE, css.title, css.titleInHead)} style={titleStyles}>
                    {title}
                </div>
            )}
        </SurveySection>
    );
});
SurveyTitle.displayName = 'SurveyTitle';
