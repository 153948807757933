import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useMemo } from 'react';

import { setHtml } from '@webapp/common/lib/ui';
import { striptags } from '@webapp/common/lib/utils';
import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import type { ISurveyQuestionGroupModel } from '@webapp/common/resources/mst-survey/question_group';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CustomStylesCtx, useTextStyle } from '@webapp/ui/lib/custom-styles';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';
import { Textfield } from '@webapp/ui/lib/textfield';

import css from './name.css';

export const Name = observer<SurveyQuestion>(({ question: { answers, groups } }) => {
    const handleChangeValue = useCallback(
        (answer: ISurveyQuestionAnswerModel, group: ISurveyQuestionGroupModel) => (value) =>
            group.changeResponseValue(answer.id, value),
        []
    );
    const { text: textStyle } = useContext(CustomStylesCtx);
    const { itemsGap } = useTextStyle();
    const withTitle = useMemo(() => answers.some((answer) => !!answer.value[0]), [answers]);
    const gridStyle: CSSProperties = useMemo(() => {
        const colCount = groups.length + Number(withTitle);
        return {
            gridTemplateColumns: `repeat(${colCount}, minmax(min-content, max-content))`,
            rowGap: itemsGap
        };
    }, [groups.length, itemsGap, withTitle]);

    const fieldsStyle = useMemo(
        () => ({
            rowGap: itemsGap
        }),
        [itemsGap]
    );

    return (
        <div className={cn(CssQuestionType.QUESTION, CssQuestionType.NAME, css.name)} style={gridStyle}>
            {answers.map((answer) => (
                <div
                    className={cn(CssPageBlock.ANSWER, css.row, !withTitle && css.noLabel)}
                    key={answer.id}
                    style={fieldsStyle}
                >
                    {withTitle && (
                        <div
                            className={cn(CssUiComponent.LABEL, css.cell, css.label)}
                            style={textStyle}
                            {...setHtml((answer.value[0] as string) || '')}
                        />
                    )}
                    <div className={css.fields} style={fieldsStyle}>
                        {groups.map((group) => {
                            const response = group.getResponse(answer.id);
                            const value = (response?.value || '') as string;
                            const onChange = (v: any): void => void handleChangeValue(answer, group)(v);

                            return (
                                <div className={cn(CssUiComponent.CELL, css.cell, css.answer)} key={group.id}>
                                    <div className={css.field}>
                                        <div className={css.text}>
                                            <Textfield
                                                transparent
                                                placeholder={group.name ? striptags(group.name) : undefined}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
});
Name.displayName = 'Name';
