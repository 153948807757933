import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo } from 'react';
import type { CSSProperties } from 'react';
import { DEFAULT_DISTRIBUTE_SCORE } from 'resources/questions/fillers';

import { setHtml } from '@webapp/common/lib/ui';
import { applyStylesToClassNameWithImportant, striptags } from '@webapp/common/lib/utils';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CustomStylesCtx, useTextStyle } from '@webapp/ui/lib/custom-styles';
import { Slider } from '@webapp/ui/lib/slider';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import css from './scale-distribute.css';

export const ScaleDistribute = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            params: { distributeLabel, distributeScore: disScore, distributeUnit }
        }
    }) => {
        const { answerStyle, listStyle } = useTextStyle();
        const distributeScore = parseInt(disScore.toString()) || DEFAULT_DISTRIBUTE_SCORE;
        const total = answers.reduce((value, answer) => value + ((answer?.response.value as number) || 0), 0);
        const budget = Math.max(distributeScore - total, 0);
        const { text: textStyle } = useContext(CustomStylesCtx);
        const maxLen = useMemo(
            () => answers.reduce((max, { value: [label] }) => Math.max(max, striptags(label as string).length), 0),
            [answers]
        );

        const labelStyle: CSSProperties = useMemo(
            () => ({
                ...textStyle,
                maxWidth: maxLen < 10 ? `calc(1ch * ${maxLen})` : undefined
            }),
            [maxLen, textStyle]
        );
        const customStyles = useContext(CustomStylesCtx);
        const stylesCtx = useMemo(
            () => ({
                ...customStyles,
                textField: {
                    ...(customStyles?.textField || {}),
                    minWidth: 'unset'
                }
            }),
            [customStyles]
        );

        const style: CSSProperties = useMemo(
            () => ({
                ['--scale-label-width' as any]: maxLen < 10 ? `calc(1ch * ${maxLen})` : undefined,
                ...listStyle
            }),
            [listStyle, maxLen]
        );

        useEffect(() => {
            applyStylesToClassNameWithImportant('.customized-track', {
                backgroundColor: answerStyle.color
            });
        }, [answerStyle.color]);

        return (
            <div
                className={cn(CssQuestionType.QUESTION, CssQuestionType.SCALE_DISTRIBUTE, css.distribute)}
                style={style}
            >
                <div className={css.text} style={textStyle}>
                    <span className={CssUiComponent.LABEL}>{distributeLabel}</span>
                    <span className={cn(CssUiComponent.LABEL, css.score)} style={answerStyle}>
                        {budget}
                    </span>
                    <span className={CssUiComponent.LABEL}>{distributeUnit}</span>
                </div>
                <div className={css.answers} style={listStyle}>
                    {answers.map(({ id, response: { change, value: responseValue }, value: [value1] }) => {
                        responseValue = Number(responseValue);
                        const limit = Math.max(budget + responseValue, 0);
                        return (
                            <div className={cn(CssPageBlock.ANSWER, css.answer)} key={id}>
                                <div
                                    className={cn(CssUiComponent.LABEL, css.label)}
                                    style={labelStyle}
                                    {...setHtml(value1 as string)}
                                />
                                <CustomStylesCtx.Provider value={stylesCtx}>
                                    <Slider
                                        className={cn(CssUiComponent.SLIDER, css.slider)}
                                        controlClassName={css.control}
                                        defaultValue={responseValue}
                                        fieldClassName={css.field}
                                        limit={limit}
                                        max={distributeScore}
                                        trackClassName='customized-track'
                                        onChange={change}
                                    />
                                </CustomStylesCtx.Provider>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
);
ScaleDistribute.displayName = 'ScaleDistribute';
