import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { forwardRef, useCallback, useMemo, useRef } from 'react';

import { mapQuestionBlockPositionToAlignItems, setHtml } from '@webapp/common/lib/ui';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { Attach, Remove } from '@webapp/ui/lib/icons';
import { Loader } from '@webapp/ui/lib/loader';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import css from './file-upload.css';

const inputStyle = { display: 'none' };

const Upload = forwardRef<
    HTMLInputElement,
    {
        style: CSSProperties;
        loading?: boolean;
        onClick: () => void;
        buttonStyle: CSSProperties;
        uploadButtonName: string;
        onChange: ({ target: { files } }: { target: { files: any } }) => Promise<void>;
        fileLimitExt: Array<string>;
    }
>(({ buttonStyle, fileLimitExt, loading, onChange, onClick, style, uploadButtonName }, ref) => (
    <div className={cn(css.upload, loading && css.hidden)}>
        <Loader className={css.loader} visible={loading} />
        <Attach style={style} />
        <div className={css.btn} style={buttonStyle} onClick={onClick}>
            {uploadButtonName}
        </div>
        <input
            multiple
            accept={fileLimitExt.join(',')}
            ref={ref}
            style={inputStyle}
            type='file'
            onChange={(v: any) => void onChange(v)}
        />
    </div>
));
Upload.displayName = 'Upload';

const Error: FC<{
    errorText: string;
}> = ({ errorText }) => <div className={cn(CssUiComponent.INVALID, css.error)} {...setHtml(errorText)} />;

const Answer: FC<{
    style: CSSProperties;
    onDelete: () => void;
    iconStyle: { color: string };
    name: string;
}> = ({ iconStyle, name, onDelete, style }) => (
    <div className={cn(CssPageBlock.ANSWER, css.file)} style={style}>
        <span className={CssUiComponent.LABEL}>{name}</span>
        <Remove className={css.deleteIcon} style={iconStyle} onClick={onDelete} />
    </div>
);

export const FileUpload = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            deleteFile,
            errorText,
            filesLoading,
            invalid,
            params: { fileAmount, fileLimitExt, uploadButtonName },
            survey,
            uploadFile,
            validate
        }
    }) => {
        const { questionBlockPosition } = survey.info.design.view;
        const input = useRef<HTMLInputElement>(null);
        const { answerStyle, itemsGap, textStyle } = useTextStyle();
        const computedFontSize = parseInt(`${textStyle.fontSize}`) - 4;
        const fontSize = isNaN(computedFontSize) ? textStyle.fontSize : `${computedFontSize}px`;
        const style: CSSProperties = useMemo(
            () => ({
                ...textStyle,
                fontSize
            }),
            [fontSize, textStyle]
        );
        const iconStyle = useMemo(
            () => ({ color: answerStyle.color, height: fontSize }),
            [answerStyle.color, fontSize]
        );

        const listStyle: CSSProperties = useMemo(
            () => ({
                alignItems: mapQuestionBlockPositionToAlignItems[questionBlockPosition],
                gap: itemsGap
            }),
            [itemsGap, questionBlockPosition]
        );

        const handleClick = useCallback(() => {
            input.current?.click();
        }, []);

        const handleChooseFile = useCallback(
            async ({ target: { files } }) => {
                await uploadFile(files);

                if (invalid) {
                    validate();
                }
            },
            [invalid, uploadFile, validate]
        );

        const handleDeleteFile = useCallback(
            (id: number) => () => {
                void deleteFile(id);
            },
            [deleteFile]
        );

        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.FILE_UPLOAD, css.files)} style={listStyle}>
                {errorText && <Error errorText={errorText} />}
                {answers &&
                    answers.map(({ file: { id, name }, id: answerId }) => (
                        <Answer
                            iconStyle={iconStyle}
                            key={answerId}
                            name={name}
                            style={style}
                            onDelete={handleDeleteFile(id)}
                        />
                    ))}
                {answers.length < fileAmount && (
                    <Upload
                        buttonStyle={style}
                        fileLimitExt={fileLimitExt}
                        loading={filesLoading}
                        ref={input}
                        style={iconStyle}
                        uploadButtonName={uploadButtonName}
                        onChange={handleChooseFile}
                        onClick={handleClick}
                    />
                )}
            </div>
        );
    }
);
FileUpload.displayName = 'FileUpload';
