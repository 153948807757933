import type { Options, Placement, PositioningStrategy } from '@popperjs/core';
import { usePopper as usePop } from 'react-popper';

import { useDeepMemo } from '@webapp/common/hooks/use-deep-memo';

import { maxWidth, sameWidth } from './modifiers';

const popperOptionsBase = {
    placement: 'bottom-start' as Placement,
    strategy: 'fixed' as PositioningStrategy
};

const popperOptsSameWidth = {
    ...popperOptionsBase,
    modifiers: [sameWidth]
};

const popperOptsMaxWidth = {
    ...popperOptionsBase,
    modifiers: [maxWidth]
};

type PopperHook = ReturnType<typeof usePop> & {
    popperOptions: Partial<Options>;
};

export const usePopper = (referenceRef, popperRef, maxWidth: boolean): PopperHook => {
    const popperOptions = maxWidth ? popperOptsMaxWidth : popperOptsSameWidth;
    const cb = usePop(referenceRef, popperRef, popperOptions);

    return useDeepMemo({ ...cb, popperOptions });
};
