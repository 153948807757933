import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useMemo } from 'react';
import { PLACEHOLDER } from 'resources/questions/fillers';

import {
    dateFormat2IMask,
    DateTimeType,
    hours12Options,
    hours24Options,
    minutesOptions,
    TimeFormat
} from '@webapp/common/lib/date';
import { setHtml } from '@webapp/common/lib/ui';
import { leadingZero } from '@webapp/common/lib/utils';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';
import { DatePicker } from '@webapp/ui/lib/datepicker';
import type { SelectOption } from '@webapp/ui/lib/select';
import { StyledDropdown } from '@webapp/ui/lib/styled-dropdown';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';
import { Textfield, TextfieldType } from '@webapp/ui/lib/textfield';

import css from './datetime.css';

const style = {
    width: 'auto !important',
    minWidth: 'initital'
};

const DateInput: FC<{
    date: Date;
    dateFormat: string;
    mask: string;
    onChange: (v: any) => void;
    placeholder: string;
}> = ({ date, dateFormat, mask, onChange, placeholder }) => {
    const input = useMemo(() => <Textfield transparent mask={mask} style={style} type={TextfieldType.DATE} />, [mask]);

    return (
        <div className={css.date}>
            <div className={css.field}>
                <DatePicker
                    className={css.inputText}
                    customInput={input}
                    dateFormat={dateFormat}
                    placeholder={placeholder}
                    selected={date}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

const TimeInput: FC<{
    hours: string;
    hoursOptions: Array<SelectOption>;
    minutes: string;
    onChange: (field: string) => (v: any) => void;
}> = ({ hours, hoursOptions, minutes, onChange }) => (
    <div className={css.time}>
        <div className={css.field}>
            <StyledDropdown
                fullWidth
                className={css.inputText}
                options={hoursOptions}
                placeholder={PLACEHOLDER.EMPTY}
                value={hours || ''}
                onChange={onChange('hours')}
            />
        </div>
        <div className={css.divider}>:</div>
        <div className={css.field}>
            <StyledDropdown
                fullWidth
                className={css.inputText}
                options={minutesOptions}
                placeholder={PLACEHOLDER.EMPTY}
                value={minutes || ''}
                onChange={onChange('minutes')}
            />
        </div>
    </div>
);

export const Datetime = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            params: {
                dateFormat: { format: dateFormat, label: datePlaceholder },
                dateType,
                timeFormat
            }
        }
    }) => {
        // TODO use global context everywhere
        const dateMask = useMemo(() => dateFormat2IMask(dateFormat), [dateFormat]);
        const showTime = dateType === DateTimeType.TIME || dateType === DateTimeType.DATE_TIME;
        const showDate = dateType === DateTimeType.DATE_TIME || dateType === DateTimeType.DATE;
        const withLabel = answers.some(({ value: [label] }) => !!label);
        const colCount = Number(showTime) + Number(showDate) + Number(withLabel);

        const customStyles = useContext(CustomStylesCtx);
        const { text: textStyle } = customStyles;
        const computedFontSize = parseInt(`${textStyle.fontSize}`) * 0.75;
        const labelStyle: CSSProperties = useMemo(
            () => ({
                ...textStyle,
                fontSize: isNaN(computedFontSize) ? textStyle.fontSize : `${computedFontSize}px`
            }),
            [computedFontSize, textStyle]
        );
        const { activeItem, item, label: label1, root } = customStyles.select;
        const componentStyles = useMemo(
            () => ({
                ...customStyles,
                select: {
                    ...customStyles.select,
                    root: { ...root, minWidth: 40, width: 'auto' },
                    item: { ...item, width: 'auto' },
                    activeItem: { ...activeItem, width: 'auto' },
                    label: { ...label1, width: 'auto' }
                }
            }),
            [activeItem, customStyles, item, label1, root]
        );
        const hoursOptions = timeFormat === TimeFormat.TWENTY_FOUR ? hours24Options : hours12Options;

        const gridStyle = useMemo(
            () => ({
                gridTemplateColumns: `repeat(${colCount}, minmax(min-content, max-content))`
            }),
            [colCount]
        );

        const handleDateChange = (answer, date) => (value: Date) => {
            const newDate = date ? new Date(date) : new Date();
            if (value) {
                newDate.setFullYear(value.getFullYear());
                newDate.setMonth(value.getMonth());
                newDate.setDate(value.getDate());
            }
            answer.response.change(newDate.toISOString());
        };

        const handleChange = useCallback(
            (answer, date) => (type: 'date' | 'hours' | 'minutes') => (value) => {
                const newDate = date ? new Date(date) : new Date();

                if (type === 'hours') {
                    newDate.setHours(Number(value));
                } else if (type === 'minutes') {
                    newDate.setMinutes(Number(value));
                }
                answer.response.change(newDate.toISOString());
            },
            []
        );

        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.DATETIME, css.list)} style={gridStyle}>
                {answers.map((answer) => {
                    const {
                        id,
                        response,
                        value: [label]
                    } = answer;
                    const date = response.value && new Date(response.value as string);
                    const hours = date && leadingZero(date.getHours());
                    const minutes = date && leadingZero(date.getMinutes());

                    return (
                        <div className={cn(CssPageBlock.ANSWER, css.answer)} key={id}>
                            {withLabel && (
                                <div
                                    className={cn(CssUiComponent.LABEL, css.label)}
                                    style={labelStyle}
                                    {...setHtml((label as string) || '&nbsp;')}
                                />
                            )}
                            {showDate && (
                                <DateInput
                                    date={date}
                                    dateFormat={dateFormat}
                                    mask={dateMask}
                                    placeholder={datePlaceholder}
                                    onChange={handleDateChange(answer, date)}
                                />
                            )}
                            {showTime && (
                                <CustomStylesCtx.Provider value={componentStyles}>
                                    <TimeInput
                                        hours={hours}
                                        hoursOptions={hoursOptions}
                                        minutes={minutes}
                                        onChange={handleChange(answer, date)}
                                    />
                                </CustomStylesCtx.Provider>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
);
Datetime.displayName = 'Datetime';
