import { observer } from 'mobx-react-lite';
import type { FC } from 'react';

import type { ISurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';

import { PwdAuth } from './pwd-auth';
import { VkAuth } from './vk-auth';

import css from './auth.css';

export const AuthView: FC<{
    survey: ISurveyStore;
}> = observer(({ survey }) => {
    const { askPassword, askVkAuth } = survey;

    return (
        <div className={css.auth}>
            {askPassword && <PwdAuth survey={survey} />}
            {askVkAuth && <VkAuth survey={survey} />}
        </div>
    );
});
