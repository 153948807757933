import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { useMobile } from '@webapp/common/hooks/use-mobile';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CheckboxDumb } from '@webapp/ui/lib/checkbox-dumb';
import { Rating } from '@webapp/ui/lib/rating';
import { CssQuestionType, PLACEHOLDER } from '@webapp/ui/lib/survey-custom';

import { CANTRATE_ID } from 'components/questions/constants';

import css from './star.css';

export const Star: FC<SurveyQuestion> = observer(
    ({
        question: {
            params: { amount, color, size, cantRate, cantRateText },
            response: { change, value }
        }
    }) => {
        const isCheckedInit = value === CANTRATE_ID;
        const [cantRateChecked, setСantRateChecked] = useState(isCheckedInit);

        const handleChecked = useCallback(() => {
            setСantRateChecked(true);
            change(CANTRATE_ID);
        }, []);

        const isMobile = useMobile();
        const adjustedSize = isMobile ? 1 : size;

        const onChange = useCallback(
            (v: number) => {
                setСantRateChecked(false);
                change(v);
            },
            [change]
        );

        return (
            <div className={css.container}>
                <Rating
                    className={CssQuestionType.STARS}
                    color={color}
                    count={amount}
                    defaultValue={cantRateChecked ? 0 : (value as number)}
                    isEmpty={cantRateChecked}
                    size={adjustedSize}
                    onChange={onChange}
                />
                {cantRate && (
                    <div>
                        <CheckboxDumb
                            checked={cantRateChecked}
                            className={css.cantRateCheckbox}
                            label={cantRateText || PLACEHOLDER.CANT_RATE}
                            round={false}
                            onChange={handleChecked}
                        />
                    </div>
                )}
            </div>
        );
    }
);
Star.displayName = 'Star';
