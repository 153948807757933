import { useCallback, useEffect, useRef, useState } from 'react';
import type { KeyboardEventHandler } from 'react';

import { EyePassword } from '../icons';

import css from './password.css';

export const Password: FC<{
    value: string;
    onChange: any;
    onEnter: KeyboardEventHandler<HTMLInputElement>;
}> = ({ onChange = () => null, onEnter, value }) => {
    const [showValue, setShowValue] = useState(false);
    const inpRef = useRef(null);

    const handleChange = useCallback(
        ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (target) {
                return onChange(target.value);
            }
        },
        [onChange]
    );

    const handleEnter: KeyboardEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                onEnter(event);
            }
        },
        [onEnter]
    );

    const toggleShowValue = useCallback(() => {
        setShowValue(!showValue);
        inpRef.current && inpRef.current.focus();
    }, [showValue]);

    useEffect(() => {
        setTimeout(() => inpRef.current && inpRef.current.focus(), 200);
    }, []);

    return (
        <div className={css.password}>
            <input
                autoComplete='new-password'
                defaultValue={value}
                ref={inpRef}
                type={showValue ? 'text' : 'password'}
                onChange={handleChange}
                onKeyDown={handleEnter}
            />
            <span className={css.icon} onClick={toggleShowValue}>
                <EyePassword />
            </span>
        </div>
    );
};
Password.displayName = 'Password';
