import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useMemo } from 'react';

import { FetchStatus } from '@webapp/common/lib/api';
import { ColorScheme, mapQuestionBlockPositionToAlignItems } from '@webapp/common/lib/ui';
import { mergeFetchStatus } from '@webapp/common/resources/mst-survey/survey/helpers/common';
import type { ISurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';
import { Button } from '@webapp/ui/lib/button';
import { CssPageBlock } from '@webapp/ui/lib/survey-custom';

import { QuestionsContext } from 'components/questions/components/QuestionsContext';

import css from './nav.css';

const StartButton: FC<{
    darkText: boolean;
    loading: boolean;
    onClick: any;
    buttons: any;
}> = ({ buttons, darkText, loading, onClick }) => (
    <div className={css.action}>
        <Button fluid color='blue' darkText={darkText} loading={loading} onClick={onClick}>
            {buttons.replyButtonLabel || 'Начать опрос'}
        </Button>
    </div>
);

const BackButton: FC<{
    darkText: boolean;
    onClick: any;
    buttons: any;
}> = ({ buttons, darkText, onClick }) => (
    <div className={css.action}>
        <Button fluid color='blue' darkText={darkText} onClick={onClick}>
            {buttons.backButtonLabel}
        </Button>
    </div>
);

const NextButton: FC<{
    darkText: boolean;
    loading: boolean;
    onClick: () => any;
    buttons: any;
}> = ({ buttons, darkText, loading, onClick }) => (
    <div className={css.action}>
        <Button fluid color='blue' darkText={darkText} loading={loading} onClick={onClick}>
            {buttons.nextButtonLabel}
        </Button>
    </div>
);

const SendButton: FC<{
    darkText: boolean;
    loading: boolean;
    onClick: () => any;
    buttons: any;
}> = ({ buttons, darkText, loading, onClick }) => (
    <div className={css.action}>
        <Button fluid color='blue' darkText={darkText} loading={loading} onClick={onClick}>
            {buttons.sendButtonLabel}
        </Button>
    </div>
);

export const SurveyNav = observer<{
    survey: ISurveyStore;
}>(({ survey }) => {
    const {
        canGoBack,
        dontShowNextButton,
        finishStatus,
        info: {
            design: {
                buttons,
                fonts: { buttonTextColor },
                view: { buttonBlockPosition }
            },
            params: {
                other: { backToQuestion }
            }
        },
        nextButtonVisible,
        selectNextBundleStatus,
        selectPrevBundle,
        sendButtonVisible,
        startButtonVisible,
        startStatus
    } = survey;
    const { noValidCounter, setShowError } = useContext(QuestionsContext);
    const valid = !noValidCounter.length;
    const startLoading = startStatus === FetchStatus.PENDING;
    const showNextButton = nextButtonVisible && !dontShowNextButton;
    const nextLoading = selectNextBundleStatus === FetchStatus.PENDING;
    const showPrevButton = canGoBack && backToQuestion;
    const showSendButton = sendButtonVisible && !dontShowNextButton;
    const sendLoading = mergeFetchStatus(selectNextBundleStatus, finishStatus) === FetchStatus.PENDING;
    const darkText = buttonTextColor === ColorScheme.DARK;
    const style: CSSProperties = useMemo(
        () => ({
            alignItems: mapQuestionBlockPositionToAlignItems[buttonBlockPosition],
            justifyContent: mapQuestionBlockPositionToAlignItems[buttonBlockPosition]
        }),
        [buttonBlockPosition]
    );

    const onNext = useCallback(() => {
        if (!valid) {
            setShowError(true);
        } else {
            survey.selectNextBundle(false);
        }
    }, [survey, valid]);

    const startSurvey = useCallback(() => {
        survey.start();
    }, [survey]);

    return (
        <div className={cn(CssPageBlock.NAVIGATION, css.nav)} style={style}>
            {startButtonVisible && (
                <StartButton buttons={buttons} darkText={darkText} loading={startLoading} onClick={startSurvey} />
            )}
            {showPrevButton && <BackButton buttons={buttons} darkText={darkText} onClick={selectPrevBundle} />}
            {showNextButton && (
                <NextButton buttons={buttons} darkText={darkText} loading={nextLoading} onClick={onNext} />
            )}
            {showSendButton && (
                <SendButton buttons={buttons} darkText={darkText} loading={sendLoading} onClick={onNext} />
            )}
        </div>
    );
});
SurveyNav.displayName = 'SurveyNav';
