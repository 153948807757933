import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { FetchStatus } from '@webapp/common/lib/api';
import type { ISurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';

import css from './survey-error.css';

export const SurveyError = observer<{
    survey: ISurveyStore;
}>(({ survey: { contentFetchingStatus, finishStatus, stopStatus } }) => {
    const finishError = finishStatus === FetchStatus.ERROR || stopStatus === FetchStatus.ERROR;
    const loadError = contentFetchingStatus === FetchStatus.ERROR;
    const { questionGap } = useTextStyle();
    const style = useMemo(
        () => ({
            rowGap: questionGap,
            marginBottom: questionGap
        }),
        [questionGap]
    );

    if (!finishError && !loadError) {
        return null;
    }

    return (
        <div className={css.surveyError} style={style}>
            {loadError && <div>Упс... Что-то пошло не так. Попробуйте обновить страницу.</div>}
            {finishError && <div>Упс... Что-то пошло не так. Попробуйте нажать ещё раз.</div>}
        </div>
    );
});
