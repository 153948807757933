import { MouseSensor, TouchSensor, useSensors as useSens, useSensor } from '@dnd-kit/core';
import type { SensorDescriptor, SensorOptions } from '@dnd-kit/core';
import isEqual from 'lodash/isEqual';
import { useRef } from 'react';

export const useSensors = (): Array<SensorDescriptor<SensorOptions>> => {
    const sensors = useSens(
        useSensor(MouseSensor, {
            activationConstraint: {
                delay: 50,
                tolerance: 10
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 50,
                tolerance: 10
            }
        })
    );
    const prev = useRef(sensors);

    if (isEqual(prev.current, sensors)) {
        return prev.current;
    }

    prev.current = sensors;
    return sensors;
};
