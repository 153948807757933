import { observer } from 'mobx-react-lite';

import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';

import { MatrixLayout } from '../matrix-layout';

import { GroupSelect } from './group-select';

export const MatrixSelect = observer<SurveyQuestion>(({ question }) => (
    <MatrixLayout cell={GroupSelect} className={CssQuestionType.MATRIX_LIST_SELECT} question={question} />
));
MatrixSelect.displayName = 'MatrixListSelect';
