import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';

import { API_URL } from '@webapp/common';
import { setHtml } from '@webapp/common/lib/ui';
import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CheckboxDumb } from '@webapp/ui/lib/checkbox-dumb';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { SmileImage, SmilePackName } from '@webapp/ui/lib/smile-image';
import type { SmileName } from '@webapp/ui/lib/smile-image';
import { CssPageBlock, CssQuestionType, CssUiComponent, PLACEHOLDER } from '@webapp/ui/lib/survey-custom';

import { CANTRATE_ID } from 'components/questions/constants';

import { SvgInline } from './svg-inline';

import css from './smile.css';

const getExt = (filename: string) => filename?.split('.')?.pop() || false;

export const Smile = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            params: { size, smilePack, cantRate, cantRateText }
        }
    }) => {
        const count = answers.length;
        const { textStyle } = useTextStyle();
        const style = useMemo(
            () => ({
                gridTemplateColumns: `repeat(${count}, minmax(0, 1fr))`
            }),
            [count]
        );
        const [cantRateChecked, setCantRateChecked] = useState(answers?.[0]?.response?.value === CANTRATE_ID);

        const handleChecked = useCallback(() => {
            setCantRateChecked(true);
            answers?.[0]?.response.change(CANTRATE_ID);
        }, [answers]);

        const handleSelect = useCallback(
            (answer: ISurveyQuestionAnswerModel, value: boolean) => () => {
                setCantRateChecked(false);
                answer?.response.change(value);
            },
            []
        );

        return (
            <div className={css.container}>
                <div className={cn(CssQuestionType.QUESTION, CssQuestionType.SMILES, css.smiles)} style={style}>
                    {answers.map((answer) => {
                        const selected = !!answer?.response.value;
                        const label = (answer.value[1] as string) || '';
                        const name = answer.value[0] as SmileName;
                        const onChange = (): void => void handleSelect(answer, !selected)();

                        const render = useCallback(() => {
                            const isSvg = getExt(answer?.file?.path) === 'svg';
                            const path = `${API_URL + answer?.file?.path}`;

                            if (smilePack === SmilePackName.PACK4) {
                                const wSize = size === 1 ? 49 : 77;
                                return (
                                    <div style={{ width: wSize, height: wSize }}>
                                        {!isSvg ? (
                                            <img
                                                className={css.customImage}
                                                height={wSize}
                                                loading='lazy'
                                                src={path}
                                                style={{ opacity: selected ? '1' : '.6' }}
                                                width={wSize}
                                                onClick={onChange}
                                            />
                                        ) : (
                                            <SvgInline
                                                className={css.customImage}
                                                src={path}
                                                style={{ opacity: selected ? '1' : '.6' }}
                                                size={size}
                                                onClick={onChange}
                                            />
                                        )}
                                    </div>
                                );
                            }

                            return <SmileImage name={name} pack={smilePack} size={size} onClick={onChange} />;
                        }, [name, smilePack, size, onChange, selected]);

                        return (
                            <div
                                key={answer.id}
                                className={cn(CssPageBlock.ANSWER, css.smile, {
                                    [css.selected]: cantRateChecked ? false : selected
                                })}
                            >
                                {render()}
                                {label && (
                                    <div
                                        className={cn(CssUiComponent.LABEL, css.smileText)}
                                        style={textStyle}
                                        {...setHtml(label)}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
                {cantRate && (
                    <div>
                        <CheckboxDumb
                            checked={cantRateChecked}
                            className={css.cantRateCheckbox}
                            label={cantRateText || PLACEHOLDER.CANT_RATE}
                            round={false}
                            onChange={handleChecked}
                        />
                    </div>
                )}
            </div>
        );
    }
);
Smile.displayName = 'Smile';
