import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import type { CSSProperties, FC } from 'react';

import { CssPageBlock } from '@webapp/ui/lib/survey-custom';

import css from './container.css';

export const SurveySection: FC<{
    className: string;
    style?: CSSProperties;
}> = observer(({ children, className, style }) => (
    <div className={cn(CssPageBlock.SECTION, css.container, className)} style={style}>
        {children}
    </div>
));
