import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';

import { SurveyBundleType } from '@webapp/common/resources/mst-survey/survey/bundle';
import { isOfflineMode } from '@webapp/common/resources/mst-survey/survey/lib';
import type { ISurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';
import { CurrentSection } from '@webapp/common/resources/survey';
import { QuestionsSection } from '@webapp/survey/src/components/questions';
import { TestResult } from '@webapp/survey/src/components/test-results';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { RestartButton } from '@webapp/ui/lib/misc';
import { CssPageBlock } from '@webapp/ui/lib/survey-custom';

import { QuestionsContextProvider } from 'components/questions/components/QuestionsContext';

import { SurveySection } from './container';
import { SurveyError } from './error';
import { SurveyNav } from './nav';
import { TextSection } from './text-section';

import css from './survey.css';

const BundleContent = observer<{
    survey: ISurveyStore;
}>(({ survey }) => {
    const {
        currentBundle: { questions, text, type },
        scrollToQuestionId
    } = survey;

    const resetScroll = useCallback(() => {
        if (survey) {
            survey.setScrollToQuestionId(-1);
        }
    }, [survey]);

    switch (type) {
        case SurveyBundleType.QUESTIONS:
            return (
                <QuestionsSection
                    questions={questions}
                    resetScroll={resetScroll}
                    scrollToQuestionId={scrollToQuestionId}
                    survey={survey}
                />
            );
        case SurveyBundleType.TEXT:
            return <TextSection text={text} />;
    }

    return null;
});
BundleContent.displayName = 'BundleContent';

export const SurveyContent = observer<{
    marginStyle: CSSProperties;
    survey: ISurveyStore;
}>(({ marginStyle, survey }) => {
    const { currentBundle, currentSection, info, promocode, showWelcomePage } = survey;
    const {
        code,
        params: {
            main: { completePageText }
        }
    } = info;
    const showNav = currentSection !== CurrentSection.TEST_RESULT;
    const showCompletePage = currentSection === CurrentSection.FINISH;
    const showBundles = Boolean(currentSection === CurrentSection.BUNDLES && currentBundle);
    const showTestResult = currentSection === CurrentSection.TEST_RESULT;

    const completePageTextWithPromocodes = useMemo(
        () => (completePageText ? completePageText.replace(/{promocode}/gi, promocode) : null),
        [completePageText, promocode]
    );
    const offline = isOfflineMode();
    const { textStyle } = useTextStyle();

    const style = useMemo(
        () =>
            showWelcomePage
                ? {
                      paddingRight: marginStyle.paddingRight,
                      paddingLeft: marginStyle.paddingLeft,
                      paddingBottom: marginStyle.paddingBottom
                  }
                : marginStyle,
        [marginStyle, showWelcomePage]
    );

    const isComplete = showCompletePage || code === 68 || code === 70;

    return (
        <div
            style={style}
            className={cn(CssPageBlock.CONTENT, css.content, {
                [css.startText]: currentSection === CurrentSection.START
            })}
        >
            <QuestionsContextProvider>
                <SurveySection className={css.container}>
                    {showTestResult && <TestResult survey={survey} />}
                    {showBundles && <BundleContent survey={survey} />}
                    {isComplete && (
                        <>
                            <TextSection style={textStyle} text={completePageTextWithPromocodes} />
                            {offline && <RestartButton />}
                        </>
                    )}
                    <SurveyError survey={survey} />
                    {showNav && !isComplete && <SurveyNav survey={survey} />}
                </SurveySection>
            </QuestionsContextProvider>
        </div>
    );
});
SurveyContent.displayName = 'SurveyContent';
