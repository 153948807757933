import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useMemo } from 'react';
import { PLACEHOLDER } from 'resources/questions/fillers';

import { useMobile } from '@webapp/common/hooks/use-mobile';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';
import { StyledDropdown } from '@webapp/ui/lib/styled-dropdown';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';

import css from './dropdown.css';

export const Dropdown = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            response: { change, value = '' }
        }
    }) => {
        const isMobile = useMobile();
        const options = useMemo(
            () => answers.map((answer) => ({ label: String(answer.value[0]), value: answer.id })),
            [answers]
        );
        const ctx = useContext(CustomStylesCtx);
        const customStyles = useMemo(
            () => ({
                ...ctx,
                select: {
                    ...ctx.select,
                    root: { ...ctx.select.root, maxWidth: '40vh', width: isMobile ? '100%' : '400px' }
                }
            }),
            [ctx, isMobile]
        );
        const onChange = useCallback((v: string | number) => void change(v), [change]);

        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.DROPDOWN, css.dropdown)}>
                <CustomStylesCtx.Provider value={customStyles}>
                    <StyledDropdown
                        fullWidth
                        searchable
                        controlClassName={css.control}
                        options={options}
                        placeholder={PLACEHOLDER.EMPTY}
                        value={value as string}
                        onChange={onChange}
                    />
                </CustomStylesCtx.Provider>
            </div>
        );
    }
);
Dropdown.displayName = 'Dropdown';
