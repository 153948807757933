import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useLayoutEffect, useMemo } from 'react';

import { clearSurveyTokenCookie } from '@webapp/common/lib/cookies';
import type { ISurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';

import { Answers, CompleteText, Results } from './components';

import css from './test-results.css';

export const TestResult = observer<{
    survey: ISurveyStore;
}>(({ survey }) => {
    const {
        info: { params },
        promocode,
        questions,
        testScore: { score },
        timeAgo
    } = survey;
    const {
        main: { completePageText = '', showDoneTest, showResultTest },
        tests: { maxScoreAmount, passingScore, textDiapasons }
    } = params;
    const isPassed = passingScore === null ? null : passingScore <= score;
    const percent = Math.max(0, Math.round((score / maxScoreAmount) * 100));
    const {
        button,
        question: { text: questionTextStyles }
    } = useContext(CustomStylesCtx);

    const affectedQuestions = useMemo(() => {
        const withLogicTriggered = questions.filter(({ shownByLogic }) => shownByLogic);
        return withLogicTriggered.length > 0 ? withLogicTriggered : questions;
    }, [questions]);

    const selectedCompletePageText = useMemo(() => {
        if (showResultTest) {
            return textDiapasons.find((dia) => dia.from <= score && dia.to > score)?.text || '';
        }
        return completePageText;
    }, [showResultTest, completePageText, textDiapasons, score]);

    const completePageTextWithPromocodes = useMemo(
        () => selectedCompletePageText.replace(/{promocode}/gi, promocode),
        [selectedCompletePageText, promocode]
    );

    useLayoutEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
        if (completePageText) {
            clearSurveyTokenCookie();
        }
    }, [completePageText]);

    return (
        <div className={css.testResultsWrapper}>
            {!showResultTest && completePageTextWithPromocodes && (
                <CompleteText completePageText={completePageTextWithPromocodes} style={questionTextStyles} />
            )}
            {showResultTest && (
                <Results
                    buttonStyles={button}
                    fact={score}
                    maxScore={maxScoreAmount}
                    params={params}
                    passed={isPassed}
                    passScore={passingScore}
                    percent={percent}
                    timeAgo={timeAgo}
                    completeText={completePageTextWithPromocodes}
                />
            )}
            {showDoneTest && <Answers questions={affectedQuestions} survey={survey} />}
        </div>
    );
});
TestResult.displayName = 'TestResult';
