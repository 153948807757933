import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';

import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';
import { Textfield, TextfieldType } from '@webapp/ui/lib/textfield';

import css from './number.css';

const style = { width: '30vw', minWidth: '240px' };

const imaskOptions = {
    thousandsSeparator: ' ',
    scale: 4
};

export const Number = observer<SurveyQuestion>(
    ({
        question: {
            params: { maxLength, minLength },
            response: { change, value }
        }
    }) => {
        const min = minLength !== null ? minLength : -1 * globalThis.Number.MAX_SAFE_INTEGER;
        const max = maxLength !== null ? maxLength : globalThis.Number.MAX_SAFE_INTEGER;
        const { inputStyle } = useTextStyle();

        const changeWrapper = useCallback(
            (value) => {
                void change(value);
            },
            [change]
        );

        const finalStyle = useMemo(
            () => ({
                ...inputStyle,
                style
            }),
            [inputStyle]
        );

        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.NUMBER, css.number)}>
                <Textfield
                    transparent
                    className={css.control}
                    defaultValue={(value as string) || ''}
                    imaskOptions={imaskOptions}
                    max={max}
                    min={min}
                    style={finalStyle}
                    type={TextfieldType.IMASK_IRRATIONAL}
                    onChange={changeWrapper}
                />
            </div>
        );
    }
);
Number.displayName = 'Number';
