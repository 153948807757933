import type { Placement } from '@popperjs/core';
import isPlainObject from 'lodash/isPlainObject';

import { isBrowser } from '@webapp/common/lib/const';

export const preventDefault = (e: Event | FormEvent): void => {
    e.preventDefault();
};

export const stopEvent = (e: ReactUIEvent): void => e.stopPropagation();

const keys = new Set([37, 38, 39, 40]);
export const preventDefaultForScrollKeys = (e: KeyboardEvent): boolean | void => {
    if (keys.has[e.keyCode]) {
        preventDefault(e);
        return false;
    }
};

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
    window.addEventListener(
        'test',
        null,
        Object.defineProperty({}, 'passive', {
            get: () => {
                supportsPassive = true;
            }
        })
    );
} catch (e) {}

export interface ObjectOption {
    label: string;
    value: PrimitiveValue;
    disabled?: boolean;
}

export type SelectOption = ObjectOption | PrimitiveValue;

export const getDisabled = (option: SelectOption): any =>
    isPlainObject(option) ? !!(option as ObjectOption)?.disabled : false;

export const getLabel = (option: SelectOption): any =>
    isPlainObject(option) ? (option as ObjectOption)?.label : option;

export const getValue = (option: SelectOption): any =>
    isPlainObject(option) ? (option as ObjectOption)?.value : option;

export const getOptionText = (option: SelectOption): string =>
    typeof option === 'string'
        ? option
        : typeof option === 'object'
        ? option.label
        : typeof option === 'number'
        ? option.toString()
        : '';

export const onTop = (pos: Placement): boolean => pos === 'top-start' || pos === 'top-end';

// TODO review, refactor
const wheelOpt = isBrowser ? (supportsPassive ? { passive: false } : false) : false;
const wheelEvent: 'wheel' | 'mousewheel' | '' = isBrowser
    ? 'onwheel' in document.createElement('div')
        ? 'wheel'
        : 'mousewheel'
    : '';
export const disableScroll = (): void => {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    wheelEvent && window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
};
export const enableScroll = (): void => {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    wheelEvent && window.removeEventListener(wheelEvent, preventDefault, wheelOpt === false ? false : undefined);
    window.removeEventListener('touchmove', preventDefault, wheelOpt === false ? false : undefined);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
};
