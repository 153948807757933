import { createContext, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

export type QuestionsContextProps = {
    noValidCounter?: Array<string>;
    showError?: boolean;
    setNoValidCounter?: Dispatch<SetStateAction<Array<string>>>;
    setShowError?: Dispatch<SetStateAction<boolean>>;
};

const initialState: QuestionsContextProps = {
    noValidCounter: [],
    showError: false,
    setNoValidCounter: () => {},
    setShowError: () => {}
};

const QuestionsContext = createContext(initialState);

type QuestionsContextProviderProps = {
    children: ReactNode;
};

function QuestionsContextProvider({ children }: QuestionsContextProviderProps) {
    const [noValidCounter, setNoValidCounter] = useState<Array<string>>([]);
    const [showError, setShowError] = useState(false);

    return (
        <QuestionsContext.Provider
            value={{
                noValidCounter,
                setNoValidCounter,
                setShowError,
                showError
            }}
        >
            {children}
        </QuestionsContext.Provider>
    );
}

export { QuestionsContextProvider, QuestionsContext };
