import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';
import { QuestionType } from '@webapp/common/resources/survey';

import { QuestionsSection } from 'components/questions';

import css from '../test-results.css';

export const Answers = observer<{
    questions: Array<ISurveyQuestionModel>;
    survey: ISurveyStore;
}>(({ questions, survey }) => {
    const answers = useMemo(
        () =>
            questions.filter(
                (q) =>
                    q.type === QuestionType.TEST_FEW_OF_LIST ||
                    q.type === QuestionType.TEST_ONE_OF_LIST ||
                    q.type === QuestionType.TEST_TEXT ||
                    q.type === QuestionType.TEST_RANK
            ),
        [questions]
    );

    return (
        <div className={css.testResultsWrapper__questions}>
            <QuestionsSection readOnly showHeader questions={answers} survey={survey} />
        </div>
    );
});
Answers.displayName = 'Answers';
