import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useEffect, useMemo, useState } from 'react';
import { CurrentSection } from 'resources/survey';

import { forceHeader, isPreview } from '@webapp/common/lib/const';
import { isEmbedded, isSourcePopup, isSourceWidget } from '@webapp/common/lib/ua';
import {
    createBackgroundStyle,
    createMarginStyle,
    HeaderBackgroundType,
    HeaderTitlePosition
} from '@webapp/common/lib/ui';
import { createAccountUrl } from '@webapp/common/lib/utils';
import { useStore } from '@webapp/survey/src/store';
import { Button } from '@webapp/ui/lib/button';
import { useSurveyStyle } from '@webapp/ui/lib/custom-styles';
import { Loader } from '@webapp/ui/lib/loader';
import { CssPageBlock, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import { AgreementPopup } from 'components/agreement-popup/agreement-popup';

import { SurveyFooter } from './footer';
import { SurveyHeader } from './header';
import { SurveyIndicator } from './indicator';
import { SurveyContent } from './survey-content';
import { SurveyTitle } from './survey-title';
import { WelcomeText } from './welcome-text';

import styles from './survey.css';

const DynamicAudioRecoder = dynamic(() => import('./audio-recorder').then(({ AudioRecorder }) => AudioRecorder), {
    loading: () => <Loader />,
    ssr: false
});

export const SurveyView = observer(() => {
    const { survey } = useStore();
    const { currentSection, info, isFirstPage, showIndicator, showWelcomePage, agreementText } = survey;
    const {
        code,
        design,
        hideFooter,
        id,
        params: {
            main: { audio },
            other: { disallowTextCopy }
        },
        template,
        title
    } = info;
    const {
        brand: { headerAllPage, headerTitlePosition, headerType },
        css,
        view: { paddingBottom, paddingLeft, paddingRight, paddingTop }
    } = design;
    const designHash = JSON.stringify(design); // TODO cheaper comparator
    const surveyStyles = useSurveyStyle(design, isPreview);
    const surveyMarginStyles = useMemo(
        () => createMarginStyle(paddingTop, paddingLeft, paddingBottom, paddingRight),
        [paddingBottom, paddingLeft, paddingRight, paddingTop]
    );
    const [showAgreementPopup, setShowAgreementPopup] = useState<boolean>(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const bgStyles = useMemo(() => createBackgroundStyle(design), [designHash]);
    const showCompletePage = currentSection === CurrentSection.FINISH || code === 68 || code === 70;

    const showHeader = Boolean(
        headerType !== HeaderBackgroundType.NOT_HEAD && (!isPreview || forceHeader) && (headerAllPage || isFirstPage)
    );

    const showHeaderInBody = headerTitlePosition === HeaderTitlePosition.UNDER_HEAD && !!title;

    useEffect(() => {
        setShowAgreementPopup(Boolean(agreementText));
    }, [agreementText]);

    useEffect(() => {
        const style = document.createElement('style');
        style.innerText = css?.text;
        document.head.appendChild(style);
        return () => void document.head.removeChild(style);
    }, [css?.text]);

    const renderContent = () => (
        <div
            className={cn(
                CssPageBlock.SURVEY_INNER,
                CssUiComponent.WYSIWYG,
                styles.surveyInner,
                !(isEmbedded && !isSourcePopup && !isSourceWidget) && !hideFooter && styles.surveyInnerWithFooter
            )}
        >
            {showHeader && <SurveyHeader info={info} />}
            {showHeaderInBody && <SurveyTitle inBody info={info} />}

            {showWelcomePage && !showCompletePage && <WelcomeText marginStyles={surveyMarginStyles} survey={survey} />}
            <SurveyContent marginStyle={surveyMarginStyles} survey={survey} />
            {showIndicator && <SurveyIndicator survey={survey} />}
        </div>
    );

    const renderFooter = () => {
        if (isEmbedded && !isSourcePopup && !isSourceWidget) return;

        return !hideFooter && <SurveyFooter paddingLeft={paddingLeft} paddingRight={paddingRight} />;
    };

    const handleClosePopup = () => {
        setShowAgreementPopup(false);
    };

    return (
        <div
            style={surveyStyles}
            className={cn(styles.survey, 'survey', {
                [styles.embedded]: isEmbedded,
                [styles.noSelection]: disallowTextCopy
            })}
        >
            <div className={cn(CssPageBlock.BACKGROUND_COLOR, styles.bg)} style={bgStyles} />
            {isEmbedded ? (
                <div className={cn(styles.embeddedContentWrap, 'survey__embedded-content-wrap')}>
                    {renderContent()}
                    {renderFooter()}
                </div>
            ) : (
                <>
                    {renderContent()}
                    {renderFooter()}
                </>
            )}
            {audio && <DynamicAudioRecoder survey={survey} />}
            {!isEmbedded && template && (
                <Button className={styles.copyBtn} color='blue'>
                    <a href={createAccountUrl(`copy-template/${id}`)}>Использовать шаблон</a>
                </Button>
            )}
            {showAgreementPopup && <AgreementPopup agreementText={agreementText} handleClosePopup={handleClosePopup} />}
            <div data-iframe-height='1' />
        </div>
    );
});

SurveyView.displayName = 'SurveyView';
