import type { ModifierPhases, State } from '@popperjs/core';

export const sameWidth = {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite' as ModifierPhases,
    requires: ['computeStyles'],
    fn: ({ state }: { state: State }): void => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
    },
    effect: ({ state }: { state: State }): void => {
        const reference = state.elements.reference as HTMLElement;
        const popper = state.elements.popper;

        popper.style.width = `${reference.offsetWidth}px`;
    }
};

export const maxWidth = {
    name: 'maxWidth',
    enabled: true,
    phase: 'beforeWrite' as ModifierPhases,
    requires: ['computeStyles'],
    fn: ({ state }: { state: State }): void => {
        const reference = state.elements.reference as HTMLElement;
        const popper = state.elements.popper;
        const width = Math.max(reference.offsetWidth, popper.offsetWidth);

        popper.style.width = `${width}px`;
    },
    effect: ({ state }: { state: State }): void => {
        const reference = state.elements.reference as HTMLElement;
        const popper = state.elements.popper;
        const width = Math.max(reference.offsetWidth, popper.offsetWidth);

        popper.style.width = `${width}px`;
        reference.style.width = `calc(${width}px + 2ch)`;
    }
};
