import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import type { FC } from 'react';

import { calcNpsGradient } from '@webapp/common/lib/color';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CheckboxDumb } from '@webapp/ui/lib/checkbox-dumb';
import { CssQuestionType, PLACEHOLDER } from '@webapp/ui/lib/survey-custom';

import { CANTRATE_ID } from 'components/questions/constants';

import { LeftRight } from '../left-right';

import css from './nps.css';

const Item: FC<{
    color: string;
    selected: boolean;
    index: number;
    size: any;
    onClick: () => any;
}> = ({ color, index, onClick, selected, size }) => {
    const style = useMemo(() => ({ color }), [color]);
    return (
        <div
            style={style}
            className={cn(css.item, {
                [css.selected]: selected,
                [css.sm]: size === 1,
                [css.lg]: size === 2
            })}
            onClick={onClick}
        >
            <span>{index}</span>
        </div>
    );
};

export const Nps = observer<SurveyQuestion>(
    ({
        question: {
            params: { npsColors, npsLeftLabel, npsRightLabel, size, cantRate, cantRateText },
            response
        }
    }) => {
        const gradient = useMemo(() => calcNpsGradient(npsColors), [npsColors]);
        const selectedValue = response.value;

        const isCheckedInit = selectedValue === CANTRATE_ID;

        const [cantRateChecked, setCantRateChecked] = useState(isCheckedInit);

        const handleChecked = useCallback(() => {
            setCantRateChecked(true);
            response.change(CANTRATE_ID);
        }, [response]);

        const handleSelect = useCallback(
            (value: number) => () => {
                setCantRateChecked(false);
                response.change(value);
            },
            [response]
        );

        return (
            <div className={css.container}>
                <LeftRight className={cn(CssQuestionType.NPS)} left={npsLeftLabel} right={npsRightLabel}>
                    {gradient.map((color, index) => (
                        <Item
                            color={color}
                            index={index}
                            key={index}
                            selected={cantRateChecked ? false : selectedValue === index}
                            size={size}
                            onClick={handleSelect(index)}
                        />
                    ))}
                </LeftRight>

                {cantRate && (
                    <div
                        style={{
                            display: 'flex',
                            placeContent: 'baseline'
                        }}
                    >
                        <CheckboxDumb
                            checked={cantRateChecked}
                            className={css.cantRateCheckbox}
                            label={cantRateText || PLACEHOLDER.CANT_RATE}
                            round={false}
                            onChange={handleChecked}
                        />
                    </div>
                )}
            </div>
        );
    }
);

Nps.displayName = 'Nps';
