import cn from 'classnames';
import { useCallback, useContext, useLayoutEffect, useMemo } from 'react';
import type { FC } from 'react';

import { useClickOutside } from '@webapp/common/hooks/use-click-outside';
import { useLockScroll } from '@webapp/common/hooks/use-lock-scroll';
import { DialogCloseBtn } from '@webapp/ui/lib/close-btn';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';
import { CssUiComponent } from '@webapp/ui/lib/survey-custom';

import css from './modal.css';

export const AgreementModal: FC<{
    onClose?: () => void;
    close?: boolean;
}> = ({ children, close = true, onClose }) => {
    const { disable, enable } = useLockScroll();
    const handleClose = useCallback(() => {
        enable();
        onClose && onClose();
    }, [enable, onClose]);
    const ref = useClickOutside(handleClose, close);
    const {
        imageSlider: {
            root: { color }
        }
    } = useContext(CustomStylesCtx);
    const style = useMemo(() => ({ stroke: color, color: `${color}` }), [color]);

    useLayoutEffect(() => {
        disable();
        return enable;
    }, [disable, enable]);

    return (
        <div className={cn(CssUiComponent.MODAL, css.modal)}>
            <div className={css.content} ref={ref}>
                {children}
            </div>
            {close && <DialogCloseBtn className={css.close} style={style} onClick={handleClose} />}
        </div>
    );
};
