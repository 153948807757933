import cn from 'classnames';
import { useCallback, useContext } from 'react';

import { nop } from '@webapp/common/lib/utils';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';
import { CssUiComponent } from '@webapp/ui/lib/survey-custom';

import { getDisabled, getLabel, stopEvent } from '../lib';
import type { SelectOption } from '../lib';

import css from './options.css';

export const Option: FC<{
    option: SelectOption;
    onClick: (option: SelectOption) => void;
    selected: boolean;
    top: boolean;
    small: boolean;
    renderLabel?: (label: string) => ReactNode;
}> = ({ onClick, option, selected, small, top, renderLabel }) => {
    const disabled = getDisabled(option);
    const label = renderLabel ? renderLabel(getLabel(option)) : getLabel(option);

    const context = useContext(CustomStylesCtx);
    const {
        select: { activeItem, item }
    } = context;
    const style = Object.assign(
        { '--hover': activeItem?.backgroundColor, color: undefined } as any,
        item,
        selected ? activeItem : {}
    );
    const className = cn(css.item, {
        [css.selected]: selected,
        [css.disabled]: disabled,
        [css.top]: top,
        [css.small]: small
    });

    const click = useCallback(
        (e) => {
            stopEvent(e);
            disabled ? nop() : onClick(option);
        },
        [disabled, onClick, option]
    );

    return (
        <div className={cn(CssUiComponent.SELECT_OPTION, className)} style={style} onClick={click}>
            {label}
        </div>
    );
};
