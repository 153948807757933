import cn from 'classnames';
import type { DetailedHTMLProps, HTMLAttributes } from 'react';

import { setHtml } from '@webapp/common/lib/ui';

export const TextSection: FC<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
        text: string;
        className?: string;
        style?: CSSProperties;
    }
> = ({ className, style, text }) => (
    <div className={cn(className, 'wysiwyg')} style={style}>
        <div {...setHtml(text)} />
    </div>
);
