import { useLayoutEffect, useState } from 'react';

import { useWindowSize } from './use-window-size';

interface Size {
    width: number;
    height: number;
}

export const useElementSize = (ref: MutableRefObject<HTMLElement>): Size => {
    const ws = useWindowSize();
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0
    });

    useLayoutEffect(() => {
        ref.current &&
            setSize({
                width: ref?.current?.offsetWidth || 0,
                height: ref?.current?.offsetHeight || 0
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws, ref.current]);

    return size;
};
