import { useCallback, useEffect, useMemo, useRef } from 'react';

import { applyStylesToElement, applyToChildren, calcHeight, getChildrenBoxes } from './lib';

export const useFixedHeader = (
    fixedHeaderStyle = {}
): {
    headerRef: MutableRefObject<any>;
    rootRef: MutableRefObject<any>;
    bgRef: MutableRefObject<any>;
} => {
    const headerRef = useRef<HTMLDivElement>(null);
    const rootRef = useRef<HTMLDivElement>(null);
    const bgRef = useRef<HTMLDivElement>(null);
    const surveyHeaderRef = useRef(document.getElementById('headerWrap'));

    const position = useCallback(() => {
        if (!headerRef.current || !rootRef.current) return;

        requestAnimationFrame(() => {
            if (!rootRef.current || !headerRef.current) return;
            const boxes = getChildrenBoxes(headerRef.current);
            const headerHeight = parseFloat(calcHeight(boxes)) || 0;
            const { bottom, height, top: headerTop, width } = rootRef.current.getBoundingClientRect();
            const shouldStick = height > window.innerHeight;
            let translate = -headerTop;

            if (!shouldStick) return;

            let offset = 50;
            surveyHeaderRef.current = surveyHeaderRef.current || document.getElementById('headerWrap');
            const withStickyHeader =
                surveyHeaderRef.current && surveyHeaderRef.current.classList.toString().search('sticky') > -1;

            if (withStickyHeader) {
                const { height } = surveyHeaderRef.current.getBoundingClientRect();
                offset = height + 50;
                translate += height;
            }

            const bottomBorder = headerHeight + offset;
            const reposition = headerTop < offset && bottom > bottomBorder;
            const commonStyles: CSSProperties = {
                ...fixedHeaderStyle,
                transform: `translateY(${reposition ? translate : 0}px)`,
                backfaceVisibility: reposition ? 'hidden' : 'initial'
            };

            applyToChildren(
                headerRef.current,
                {
                    ...commonStyles,
                    display: reposition ? 'flex' : 'none',
                    zIndex: reposition ? '100' : '0'
                },
                !reposition
            );

            applyStylesToElement(
                bgRef.current,
                {
                    ...commonStyles,
                    display: reposition ? 'initial' : 'none',
                    height: `${headerHeight}px`,
                    width: `${width}px`,
                    zIndex: reposition ? '99' : '0'
                },
                !reposition
            );
        });
    }, [fixedHeaderStyle]);

    useEffect(() => {
        position();

        window.addEventListener('scroll', position);

        return () => {
            window.removeEventListener('scroll', position);
        };
    }, [position]);

    return useMemo(
        () => ({
            headerRef,
            rootRef,
            bgRef
        }),
        []
    );
};
