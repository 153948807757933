import { DialogCloseBtn } from '@webapp/ui/lib/close-btn';

import css from './agreement-popup.css';

export const AgreementPopup: FC<{ agreementText: string; handleClosePopup: () => void }> = ({
    agreementText,
    handleClosePopup
}) => {
    return (
        <div className={css.agreementPopup}>
            <div dangerouslySetInnerHTML={{ __html: agreementText }} />
            <DialogCloseBtn className={css.close} onClick={handleClosePopup} />
        </div>
    );
};
