import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { createFooterContainerStyle } from '@webapp/common/lib/ui';
import { LogoGrey } from '@webapp/ui/lib/icons';

import { SurveySection } from '../container';

import css from './footer.css';

export const SurveyFooter = observer<{
    paddingLeft: number;
    paddingRight: number;
}>(({ paddingLeft, paddingRight }) => {
    const style = useMemo(() => createFooterContainerStyle(paddingLeft, paddingRight), [paddingRight, paddingLeft]);

    return (
        <footer className={css.footer}>
            <div className={css.footerWrap}>
                <SurveySection className={css.container} style={style}>
                    <a
                        className={css.copy}
                        href='https://www.testograf.ru/?utm_source=testograf&utm_campaign=footer'
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <LogoGrey className={css.logo} />
                    </a>
                </SurveySection>
            </div>
        </footer>
    );
});
SurveyFooter.displayName = 'SurveyFooter';
