import { observer } from 'mobx-react-lite';

import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';

import { MatrixLayout } from '../matrix-layout';

import { Group } from './group';

export const MatrixDropdown = observer<SurveyQuestion>(({ question }) => (
    <MatrixLayout cell={Group} className={CssQuestionType.MATRIX_DROPDOWN} question={question} />
));
MatrixDropdown.displayName = 'MatrixListDropdown';
