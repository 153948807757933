import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { BodyScrollOptions } from 'body-scroll-lock';
import { useCallback, useMemo, useRef } from 'react';

const options: BodyScrollOptions = {
    reserveScrollBarGap: true
};

export const useLockScroll = (): {
    enable: () => void;
    disable: () => void;
} => {
    const body = useRef(document.querySelector('body'));

    const enable = useCallback(() => {
        if (body.current) {
            enableBodyScroll(body.current);
            clearAllBodyScrollLocks();
        }
    }, []);

    const disable = useCallback(() => {
        if (body.current) {
            disableBodyScroll(body, options);
        }
    }, []);

    return useMemo(() => ({ enable, disable }), [disable, enable]);
};
