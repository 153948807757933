import { useMemo } from 'react';

import { createMarginStyle } from '@webapp/common/lib/ui';
import type { ISurveyInfoDesignModel } from '@webapp/common/resources/mst-survey/info_design';

export const useBodyTitleStyle = (design: ISurveyInfoDesignModel): CSSProperties => {
    const {
        view: { paddingBottom, paddingLeft, paddingRight, paddingTop }
    } = design;
    const designHash = JSON.stringify(design); // TODO cheaper comparator
    const surveyMarginStyles: CSSProperties = useMemo(
        () => createMarginStyle(paddingTop, paddingLeft, paddingBottom, paddingRight),
        [paddingTop, paddingRight, paddingBottom, paddingLeft]
    );

    return useMemo(
        () => ({
            ...surveyMarginStyles,
            textAlign: 'center',
            width: undefined,
            height: undefined,
            right: undefined,
            top: undefined
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [designHash, surveyMarginStyles]
    );
};
