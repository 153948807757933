import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { mapQuestionBlockPositionToAlignItems, setHtml } from '@webapp/common/lib/ui';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';
import { Textfield } from '@webapp/ui/lib/textfield';

import css from './group-free-answers.css';

export const GroupFreeAnswers = observer<SurveyQuestion>(({ align, question: { answers } }) => {
    const { answerStyle, inputStyle, itemsGap, listStyle, textStyle } = useTextStyle();
    const style = useMemo(
        () => ({
            ...listStyle,
            alignItems: mapQuestionBlockPositionToAlignItems[align] // TODO provide align in listStyle
        }),
        [align, listStyle]
    );
    const rowStyle = useMemo(
        () => ({
            ...answerStyle,
            rowGap: itemsGap
        }),
        [answerStyle, itemsGap]
    );

    return (
        <div className={cn(CssQuestionType.QUESTION, CssQuestionType.GROUP_FREE_ANSWERS, css.list)} style={style}>
            {answers.map(({ id, response: { change, value: responseValue }, value: [value1] }) => (
                <div className={cn(CssPageBlock.ANSWER, css.answer)} key={id} style={rowStyle}>
                    <div
                        className={cn(CssUiComponent.LABEL, css.label)}
                        style={textStyle}
                        {...setHtml(value1 as string)}
                    />
                    <Textfield
                        transparent
                        rootClassName={css.field}
                        style={inputStyle}
                        value={(responseValue as string) || ''}
                        onChange={(v: any): void => void change(v)}
                    />
                </div>
            ))}
        </div>
    );
});
GroupFreeAnswers.displayName = 'GroupFreeAnswers';
