import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { setHtml } from '@webapp/common/lib/ui';
import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import type { ISurveyQuestionGroupModel } from '@webapp/common/resources/mst-survey/question_group';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { CssUiComponent } from '@webapp/ui/lib/survey-custom';
import { Textfield, TextfieldType } from '@webapp/ui/lib/textfield';

import type { MatrixCell } from '../matrix-layout/matrix-cell';
import cssLayout from '../matrix-layout/matrix-layout.css';

import css from './matrix-text.css';

export const MatrixTextGroup = observer<MatrixCell>(({ answer, group, height, question }) => {
    const { inputStyle, textStyle } = useTextStyle();
    const response = group.getResponse(answer.id);
    const value = (response?.value || '') as string;
    const { answerType } = question.params;
    const numeric = answerType === 'number';
    const handleChangeValue = useCallback(
        (answer: ISurveyQuestionAnswerModel, group: ISurveyQuestionGroupModel) => (value: string) => {
            group.changeResponseValue(answer.id, value);
        },
        []
    );

    return (
        <div className={cn(CssUiComponent.CELL, cssLayout.cell, css.answer)} style={{ height }}>
            <div className={css.field}>
                <div
                    className={cn(CssUiComponent.LABEL, cssLayout.cell, cssLayout.rowHeaderMobile, css.name)}
                    style={textStyle}
                    {...setHtml(group.name)}
                />
                <div className={css.text}>
                    <Textfield
                        transparent
                        style={inputStyle}
                        type={numeric ? TextfieldType.NUMBER : undefined}
                        value={value}
                        onChange={handleChangeValue(answer, group)}
                    />
                </div>
            </div>
        </div>
    );
});
MatrixTextGroup.displayName = 'Group';
