import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { setHtml } from '@webapp/common/lib/ui';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';

import css from './text-block.css';

export const TextBlock = observer<SurveyQuestion>(
    ({
        question: {
            params: { textBlock }
        }
    }) => {
        const {
            question: { text: questionTextStyles }
        } = useContext(CustomStylesCtx);

        return (
            <div
                className={cn(CssQuestionType.QUESTION, CssQuestionType.TEXT_BLOCK, css.textBlock)}
                style={questionTextStyles}
                {...setHtml(textBlock)}
            />
        );
    }
);
TextBlock.displayName = 'TextBlock';
