import { DndContext, DragOverlay, pointerWithin } from '@dnd-kit/core';
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { getSnapshot, isAlive } from 'mobx-state-tree';
import { useCallback, useContext, useMemo, useState } from 'react';

import { QuestionType } from '@webapp/common/resources/survey';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CustomStylesCtx, useTextStyle } from '@webapp/ui/lib/custom-styles';
import { SORTABLE_CONTAINER_CN, useSensors } from '@webapp/ui/lib/sortable';
import { CssPageBlock, CssQuestionType } from '@webapp/ui/lib/survey-custom';

import { ListSelectResults } from 'components/questions/types/list-select';

import { Item, SortableItem } from './item';

import css from './rank.css';

const modifiers = [restrictToVerticalAxis];

const itemStyle = {
    background: 'none'
};

export const Rank = observer<SurveyQuestion>(
    ({ question: { answers: rawAnswers, currentPoints, factTime, maxPoints, survey, targetTime, type } }) => {
        const answers = rawAnswers
            .slice()
            .sort((prevAnswer, nextAnswer) => Number(prevAnswer?.response.value) - Number(nextAnswer?.response.value));
        const items = answers.map(({ id }) => String(id));
        const { text: textStyle } = useContext(CustomStylesCtx);
        const sensors = useSensors();
        const [draggedItem, setDraggedItem] = useState(null);
        const { answerStyle, listStyle } = useTextStyle();

        const handlerIconStyle = useMemo(
            () => ({
                color: answerStyle.color
            }),
            [answerStyle.color]
        );

        const handleChangeSort = useCallback(
            ({ active, over }: DragEndEvent) => {
                setDraggedItem(null);

                const oldIndex = answers.findIndex(({ id }) => id === Number(active.id));
                // TODO check, over maybe null
                const newIndex = over ? answers.findIndex(({ id }) => id === Number(over.id)) : 0;
                const answersIds = answers.map((a) => a.id);
                const newAnswers = arrayMove(answersIds, oldIndex, newIndex);

                newAnswers.forEach((id, idx) => {
                    const answer = rawAnswers.find((a) => a.id === id);
                    if (isAlive(answer.response)) {
                        answer?.response.change(idx);
                    }
                });
            },
            [answers, rawAnswers]
        );

        const onDragStart = useCallback(
            ({ active }: DragStartEvent) => {
                const id = Number(active.id);
                const idx = answers.findIndex((a) => a.id === id);
                const dragged = getSnapshot(answers[idx]);

                setDraggedItem({ ...dragged, idx: idx + 1 });
            },
            [answers]
        );

        const showResults = type === QuestionType.TEST_RANK && (factTime || targetTime || maxPoints || currentPoints);
        const showTimer = Boolean(factTime || targetTime);

        return (
            <div className={cn(css.root, SORTABLE_CONTAINER_CN)}>
                <DndContext
                    collisionDetection={pointerWithin}
                    modifiers={modifiers}
                    sensors={sensors}
                    onDragEnd={handleChangeSort}
                    onDragStart={onDragStart}
                >
                    <SortableContext items={items} strategy={verticalListSortingStrategy}>
                        <div
                            style={listStyle}
                            className={cn(
                                CssQuestionType.QUESTION,
                                CssQuestionType.RANK,
                                SORTABLE_CONTAINER_CN,
                                css.rank
                            )}
                        >
                            {answers.map(({ id, testCheckStatus, value }, idx) => (
                                <SortableItem
                                    iconStyle={handlerIconStyle}
                                    id={id}
                                    idx={idx + 1}
                                    itemStyle={itemStyle}
                                    key={id}
                                    showAnswerIcon={Boolean(showResults)}
                                    testCheckStatus={testCheckStatus}
                                    textStyle={textStyle}
                                    value={value}
                                    className={cn(CssPageBlock.ANSWER, css.sortableItem, {
                                        [css.current]: draggedItem && draggedItem.id === id
                                    })}
                                />
                            ))}
                        </div>
                    </SortableContext>
                    <DragOverlay>
                        {draggedItem ? (
                            <Item
                                className={cn(CssPageBlock.ANSWER, css.sortableItem, css.draggable)}
                                iconStyle={handlerIconStyle}
                                idx={draggedItem.idx}
                                itemStyle={itemStyle}
                                textStyle={textStyle}
                                value={draggedItem.value}
                            />
                        ) : null}
                    </DragOverlay>
                </DndContext>
                {showResults && (
                    <ListSelectResults
                        answers={answers}
                        currentPoints={currentPoints}
                        factTime={factTime}
                        maxPoints={maxPoints}
                        pointsLabel={survey.info.params.tests.scoreLabel}
                        showTimer={showTimer}
                        targetTime={targetTime}
                    />
                )}
            </div>
        );
    }
);
Rank.displayName = 'Rank';
