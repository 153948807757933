import { useContext, useMemo } from 'react';

import { useMobile } from '@webapp/common/hooks/use-mobile';

import { CustomStylesCtx } from './custom-styles';

export const useTextStyle = (): {
    labelStyle: CSSProperties;
    answerStyle: CSSProperties;
    textStyle: CSSProperties;
    titleStyle: CSSProperties;
    itemsGap: string;
    listStyle: CSSProperties;
    inputStyle: CSSProperties;
    color: string;
    questionGap: string;
} => {
    const {
        fieldBorderColor,
        gap,
        lineHeight,
        question: { title: titleStyle },
        text,
        textField
    } = useContext(CustomStylesCtx);
    const isMobile = useMobile();
    const computedFontSize = parseInt(`${text.fontSize}`) - 4;
    const labelStyle: CSSProperties = useMemo(
        () => ({
            ...text,
            fontSize: isNaN(computedFontSize) ? text.fontSize : `${computedFontSize}px`
        }),
        [computedFontSize, text]
    );
    const itemsGap = `calc(${lineHeight} * 0.35rem)`;
    const answerStyle = useMemo(
        () => ({
            minHeight: itemsGap,
            color: fieldBorderColor
        }),
        [fieldBorderColor, itemsGap]
    );
    const questionGap = `calc(${gap} * 2rem)`;

    const listStyle = useMemo(
        () => ({
            rowGap: itemsGap
        }),
        [itemsGap]
    );

    const textStyle = useMemo(
        () =>
            isMobile
                ? {
                      ...text,
                      fontSize: `clamp(10px, 8px + 1.5vw, ${
                          isNaN(computedFontSize) ? text.fontSize : `${computedFontSize}px`
                      })`
                  }
                : { ...text },
        [computedFontSize, isMobile, text]
    );

    const inputStyle = useMemo(() => ({ ...textField, ...textStyle }), [textField, textStyle]);

    return useMemo(
        () => ({
            color: fieldBorderColor,
            labelStyle,
            answerStyle,
            textStyle,
            titleStyle,
            itemsGap: itemsGap,
            listStyle,
            inputStyle,
            questionGap
        }),
        [fieldBorderColor, labelStyle, answerStyle, textStyle, titleStyle, itemsGap, listStyle, inputStyle, questionGap]
    );
};
