import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';
import { Textfield, TextfieldType } from '@webapp/ui/lib/textfield';

import css from './long-text.css';

export const LongText = observer<SurveyQuestion>(
    ({
        question: {
            response: { change, value }
        }
    }) => {
        const { inputStyle } = useTextStyle();
        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.LONG_TEXT, css.text)}>
                <Textfield
                    transparent
                    className={css.control}
                    style={inputStyle}
                    type={TextfieldType.MULTILINE}
                    value={(value as string) || ''}
                    onChange={(v) => void change(v)}
                />
            </div>
        );
    }
);
LongText.displayName = 'LongText';
