import cn from 'classnames';
import type { FC } from 'react';
import React from 'react';

import { UnescapeHtml } from '@webapp/common/lib/utils';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { RequiredMark } from '@webapp/ui/lib/icons';
import { Textfield } from '@webapp/ui/lib/textfield';

import css from 'components/questions/question.css';

export const Comment: FC<{
    commentInvalid: boolean;
    commentText?: string;
    onChange: (v: string) => void;
    commentTextHelp: string;
    showMark: boolean;
    markColor?: string;
    className?: string;
    disabled?: boolean;
}> = ({ className, disabled, commentInvalid, commentText, commentTextHelp, showMark, onChange, markColor }) => {
    const { textStyle } = useTextStyle();
    return (
        <div style={textStyle} className={cn(css.comment, className)}>
            <Textfield
                disabled={disabled}
                className={cn(css.commentInput, {
                    [css.commentInvalid]: commentInvalid
                })}
                placeholder={UnescapeHtml(commentTextHelp || '')}
                style={textStyle}
                value={commentText || ''}
                onChange={onChange}
            />
            {showMark && (
                <span className={cn(css.requiredMarkWrap, css.commentMark)} style={{ color: markColor }}>
                    <RequiredMark />
                </span>
            )}
        </div>
    );
};
