import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { API_URL } from '@webapp/common/conf';
import { calcHeaderHeight, HeaderTitlePosition, styleStrToObject } from '@webapp/common/lib/ui';
import type { ISurveyInfo } from '@webapp/common/resources/mst-survey/info';
import { CssPageBlock } from '@webapp/ui/lib/survey-custom';

import { SurveyTitle } from '../survey-title';

import css from './header.css';

export const SurveyHeader: FC<{
    info: ISurveyInfo;
}> = observer(({ info }) => {
    const {
        design: {
            brand: {
                headerBackgroundColor,
                headerBackgroundImage,
                headerSize,
                headerStick,
                headerTitlePosition,
                logo,
                logoStyle
            }
        }
    } = info;
    const headerHeight = calcHeaderHeight(headerSize);
    const showTitleInHeader = headerTitlePosition !== HeaderTitlePosition.UNDER_HEAD;
    const headerStyles: CSSProperties = useMemo(
        () => ({
            height: `${headerHeight}px`,
            backgroundColor: headerBackgroundColor,
            backgroundImage: headerBackgroundImage ? `url(${API_URL + headerBackgroundImage})` : undefined
        }),
        [headerBackgroundColor, headerBackgroundImage, headerHeight]
    );
    const logoStyles = useMemo(() => styleStrToObject(logoStyle), [logoStyle]);

    return (
        <div className={cn(css.headerWrap, { [css.sticky]: headerStick }, 'headerWrap')} id='headerWrap'>
            <header className={cn(CssPageBlock.HEADER, css.header)} style={headerStyles}>
                {(logo || showTitleInHeader) && (
                    <SurveyTitle
                        empty={logo && !showTitleInHeader}
                        headerHeight={headerHeight}
                        inBody={false}
                        info={info}
                    />
                )}
                {logo && (
                    <div className={cn(CssPageBlock.HEADER_LOGO_CONTAINER, css.logoWrap)}>
                        <img
                            alt='logo'
                            className={cn(CssPageBlock.HEADER_LOGO, css.logoImg)}
                            loading='lazy'
                            src={API_URL + logo}
                            style={logoStyles}
                        />
                    </div>
                )}
            </header>
        </div>
    );
});
SurveyHeader.displayName = 'SurveyHeader';
