import { setHtml } from '@webapp/common/lib/ui';

import css from '../test-results.css';

export const CompleteText: FC<{
    completePageText: string;
    style: CSSProperties;
}> = ({ completePageText, style }) => (
    <div className={css.testResultsText} style={style} {...setHtml(completePageText)} />
);
