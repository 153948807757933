import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable as useSort } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useMemo } from 'react';

import { useDeepMemo } from '@webapp/common/hooks/use-deep-memo';

export interface UseSortableHook {
    listeners: DraggableSyntheticListeners;
    setNodeRef: (node: HTMLElement | null) => void;
    style: { transform?: string; transition?: string };
    active: any;
    over: any;
    isDragging: boolean;
    isSorting: boolean;
    attributes: {
        role: string;
        tabIndex: number;
        'aria-pressed': boolean | undefined;
        'aria-roledescription': string;
        'aria-describedby': string;
    };
}

export const useSortable = (id: string | number, data: any, disabled = false): UseSortableHook => {
    const { active, attributes, isDragging, isSorting, listeners, over, setNodeRef, transform, transition } = useSort({
        id: String(id),
        data,
        disabled
    });

    const style = useDeepMemo({
        userSelect: 'none',
        touchAction: 'none',
        WebkitTouchCallout: 'none',
        transform: CSS.Translate.toString(transform),
        transition
    });

    return useMemo(
        () => ({
            style,
            isDragging,
            isSorting,
            active,
            over,
            attributes,
            listeners,
            setNodeRef
        }),
        [active, attributes, isDragging, isSorting, listeners, over, setNodeRef, style]
    );
};
