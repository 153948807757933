const msgSignature = '[iFrameSizer]message:';

export enum WindowCmd {
    UpdateSurvey = 'UPD',
    SetParentHeight = 'setParentHeight',
    HideScroll = 'hideScroll',
    ShowScroll = 'showScroll',
    SetZoom = 'setZoom',
    SetFramePos = 'setFramePos'
}

export const handleWindowMessage = (cmdMap: { [k: string]: (arg?: any) => void }): ((e: any) => void) => {
    const execCmd = (cmd: string, arg: any): void => cmdMap[cmd] && cmdMap[cmd](arg);

    return ({ data }: WindowEventMap['message']) => {
        if (typeof data === 'string' && data.startsWith(msgSignature)) {
            try {
                const payload = JSON.parse(data.split(msgSignature)[1]);
                const [cmd, arg] = payload;

                switch (cmd) {
                    case WindowCmd.UpdateSurvey:
                    case WindowCmd.SetParentHeight:
                        execCmd(cmd, arg);
                        break;
                    case WindowCmd.HideScroll:
                        document.getElementsByTagName('body')[0].classList.add('hideScroll');
                        break;
                    case WindowCmd.ShowScroll:
                        document.getElementsByTagName('body')[0].classList.remove('hideScroll');
                        break;
                    case WindowCmd.SetZoom:
                        // @ts-ignore
                        document.getElementsByTagName('body')[0].style.zoom = arg || 1;
                        break;
                    case WindowCmd.SetFramePos:
                        const params = JSON.parse(arg);
                        let yOffset = params.box.top <= 0 ? Math.abs(params.box.top) : 0;
                        yOffset = Math.min(params.box.height - params.height, yOffset);
                        document.documentElement.style.setProperty('--popup-offset-y', `${yOffset}px`);
                        document.documentElement.style.setProperty('--parent-window-height', `${params.height}px`);
                        document.documentElement.style.setProperty('--parent-window-width', `${params.width}px`);
                        break;
                    default:
                }
            } catch (e) {
                console.error(e);
            }
        }
    };
};
