import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { useMobile } from '@webapp/common/hooks/use-mobile';
import { setHtml } from '@webapp/common/lib/ui';
import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { GradientNumbers } from '@webapp/ui/lib/gradient-numbers';
import { Rating } from '@webapp/ui/lib/rating';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import css from './matrix-rating.css';

const MatrixRatingRow: FC<{
    answer: ISurveyQuestionAnswerModel;
    style: React.CSSProperties;
    color: any;
    count: any;
    size: any;
    matrixType: string;
    matrixNumbersColor: Array<string>;
}> = observer(
    ({
        answer: {
            response,
            valid,
            value: [value1]
        },
        color,
        count,
        matrixNumbersColor,
        matrixType,
        size,
        style
    }) => {
        const onChange = useCallback((v: number): void => response.change(v), [response]);
        return (
            <div
                className={cn(CssPageBlock.ANSWER, css.answer, {
                    [css.answer_invalid]: valid === false
                })}
            >
                <div className={cn(CssUiComponent.LABEL, css.label)} style={style} {...setHtml(value1 as string)} />
                <div>
                    {matrixType === 'numbers' ? (
                        <GradientNumbers
                            colors={matrixNumbersColor}
                            itemsCount={count}
                            selectedValue={response.value as number}
                            size='small'
                            startsWith={1}
                            onClick={onChange}
                        />
                    ) : (
                        <Rating
                            color={color}
                            count={count}
                            defaultValue={response.value as number}
                            size={size}
                            onChange={onChange}
                        />
                    )}
                </div>
            </div>
        );
    }
);

export const MatrixRating = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            params: { amount, color, matrixNumbersColor, matrixType, size }
        }
    }) => {
        const isMobile = useMobile();
        const { listStyle, textStyle } = useTextStyle();
        const adjustedSize = isMobile ? 1 : size;

        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.START_MATRIX, css.list)} style={listStyle}>
                {answers.map((a) => (
                    <MatrixRatingRow
                        answer={a}
                        color={color}
                        count={amount}
                        key={a.id}
                        matrixNumbersColor={matrixNumbersColor}
                        matrixType={matrixType}
                        size={adjustedSize}
                        style={textStyle}
                    />
                ))}
            </div>
        );
    }
);
MatrixRating.displayName = 'MatrixRating';
