import cn from 'classnames';
import { useEffect, useRef } from 'react';

import css from './smile.css';

export const SvgInline = ({
    src,
    onClick,
    width,
    height,
    size,
    ...rest
}: {
    className?: string;
    src: string;
    width?: number;
    height?: number;
    style?: any;
    size?: number;
    onClick: () => void;
}) => {
    const ref = useRef<HTMLImageElement>(null);

    useEffect(() => {
        (async function () {
            const data = await fetch(src);
            const svgHtml = await data.text();

            if (ref.current) {
                ref.current.outerHTML = svgHtml;
            }
        })();
    }, [src]);

    return (
        <div className={cn(css.svgIcon, size === 1 ? css.iconSize1 : css.iconSize2)} onClick={onClick}>
            <img loading='lazy' ref={ref} width={width} height={height} {...rest} />
        </div>
    );
};
