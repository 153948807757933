import { MONTHS } from '@webapp/common/lib/date';

export const YEARS_RANGE = 12;

export enum DateScale {
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

export const getMonthGroups = (): Array<Array<number>> => [
    [0, 1, 2, 3],
    [4, 5, 6, 7],
    [8, 9, 10, 11]
];

export interface Params {
    date: Date;

    changeYear(year: number): void;

    changeMonth(month: number): void;

    decreaseMonth(): void;

    increaseMonth(): void;

    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
}

export const getYearGroups = (year: number): Array<Array<number>> => {
    const yearGroups: Array<Array<number>> = [];
    const group = YEARS_RANGE / 4;
    let start = year - YEARS_RANGE / 2;
    let end = start + group;

    for (let j = 0; j < group; j++) {
        const years: Array<number> = [];
        for (let i = start; i <= end; i++) {
            years.push(i);
        }
        start += group + 1;
        end = start + group;
        yearGroups.push(years);
    }

    return yearGroups;
};

export const getTitle = (type: DateScale, params: Params): string => {
    switch (type) {
        case DateScale.DAY:
            return MONTHS[params.date.getMonth()] + ' ' + params.date.getFullYear();
        case DateScale.MONTH:
            return String(params.date.getFullYear());
        case DateScale.YEAR:
            const year = params.date.getFullYear();
            return `${year - YEARS_RANGE / 2} - ${year + YEARS_RANGE / 2}`;
    }
    return '';
};
