import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import cn from 'classnames';
import { forwardRef, useMemo } from 'react';

import { setHtml } from '@webapp/common/lib/ui';
import type { TestCheckStatus } from '@webapp/common/resources/survey';
import { AnswerIcon } from '@webapp/ui/lib/misc';
import { SortableHandler } from '@webapp/ui/lib/sortable';
import { useSortable } from '@webapp/ui/lib/sortable/useSortable';

import css from './rank.css';

interface ItemProps {
    iconStyle: CSSProperties;
    textStyle: CSSProperties;
    itemStyle: CSSProperties;
    value: any;
    id: number;
    idx: number;
    className?: string;
    showAnswerIcon?: boolean;
    testCheckStatus?: TestCheckStatus;
    listeners?: DraggableSyntheticListeners;
}

export const Item = forwardRef<HTMLDivElement, Omit<ItemProps, 'id'>>(
    (
        {
            className,
            iconStyle,
            idx,
            itemStyle,
            listeners,
            showAnswerIcon,
            testCheckStatus,
            textStyle,
            value,
            ...sortableProps
        },
        ref
    ) => (
        <div className={cn(css.variant, className)} ref={ref} style={itemStyle} {...sortableProps}>
            {showAnswerIcon && <AnswerIcon checked={true} testCheckStatus={testCheckStatus} />}
            <SortableHandler
                extraClassName={css.order}
                extraStyle={textStyle}
                iconStyle={iconStyle}
                listeners={listeners}
            >
                {idx}
            </SortableHandler>
            <div className={css.text} style={textStyle} {...setHtml(value[0])} />
        </div>
    )
);
Item.displayName = 'Item';

const sortType = { type: 'rank' };

export const SortableItem: FC<ItemProps> = ({
    className,
    iconStyle,
    id,
    idx,
    itemStyle,
    showAnswerIcon,
    testCheckStatus,
    textStyle,
    value
}) => {
    const { attributes, listeners, setNodeRef, style: sortableStyle } = useSortable(String(id), sortType);

    const style = useMemo(
        () => ({
            ...itemStyle,
            ...sortableStyle
        }),
        [itemStyle, sortableStyle]
    );

    return (
        <Item
            ref={setNodeRef}
            {...attributes}
            className={className}
            iconStyle={iconStyle}
            idx={idx}
            itemStyle={style}
            listeners={listeners}
            showAnswerIcon={showAnswerIcon}
            testCheckStatus={testCheckStatus}
            textStyle={textStyle}
            value={value}
        />
    );
};
