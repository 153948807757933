import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo, useRef } from 'react';
import tinycolor from 'tinycolor2';

import { useElementSize } from '@webapp/common/hooks/use-element-size';
import { MARGIN_BASE_UNIT, roundFloat } from '@webapp/common/lib/ui';
import { leadingZero } from '@webapp/common/lib/utils';
import type { ISurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';
import { IndicatorPosition } from '@webapp/common/resources/survey';
import { QuestionTimer } from '@webapp/ui/lib/icons';

import css from './indicator.css';

export const SurveyIndicator = observer<{
    survey: ISurveyStore;
}>(
    ({
        survey: {
            info: {
                design: {
                    fonts: { elementColor: color },
                    view: { paddingLeft, paddingRight }
                },
                params: {
                    alert: { indicator: indicatorLabel },
                    other: { indicator, indicatorPosition }
                }
            },
            invalidQuestionsQuantity,
            progress,
            timeLeft
        }
    }) => {
        const width = indicator ? progress : null;
        const ref = useRef(null);
        const { height } = useElementSize(ref);
        const inPercents = typeof width === 'number' ? width * 100 : null;
        const percentIsNumber = typeof inPercents === 'number';
        const offsets = useMemo(
            () => ({
                left: parseInt(paddingLeft || 0) * parseInt(MARGIN_BASE_UNIT),
                right: parseInt(paddingRight || 0) * parseInt(MARGIN_BASE_UNIT)
            }),
            [paddingLeft, paddingRight]
        );

        const time = useMemo(() => {
            if (timeLeft) {
                const hours = leadingZero(timeLeft.hours());
                const minutes = leadingZero(timeLeft.minutes());
                const seconds = leadingZero(timeLeft.seconds());

                return `${hours}:${minutes}:${seconds}`;
            }

            return null;
        }, [timeLeft]);

        const lightColor = useMemo(() => {
            if (!color) return;

            return tinycolor(color).setAlpha(0.2).toRgbString();
        }, [color]);

        const colorStyle = useMemo(() => ({ color }), [color]);
        const style1 = useMemo(() => ({ backgroundColor: lightColor }), [lightColor]);
        const style2 = useMemo(
            () => ({ width: `${inPercents ? roundFloat(inPercents) : 0}%`, backgroundColor: color }),
            [color, inPercents]
        );

        const indicatorStyle = useMemo(
            () => ({
                marginLeft: `${offsets?.left / 2 + 3}%`,
                marginRight: `${offsets?.right / 2 + 3}%`,
                paddingLeft: `${offsets?.left / 2 + 1.5}%`,
                paddingRight: `${offsets?.left / 2 + 1.5}%`,
                marginTop: ref.current && indicatorPosition === IndicatorPosition.TOP ? `${-1 * height}px` : undefined
            }),
            [height, indicatorPosition, lightColor, offsets?.left, offsets?.right]
        );

        return (
            <div
                ref={ref}
                style={indicatorStyle}
                className={cn(css.indicatorBlock, {
                    [css.invalid]: invalidQuestionsQuantity,
                    [css.withoutIndicator]: inPercents === null,
                    [css.posTop]: indicatorPosition === IndicatorPosition.TOP
                })}
            >
                <div className={css.top}>
                    {percentIsNumber && (
                        <div className={css.inNumbers} style={colorStyle}>
                            {`${inPercents ? inPercents.toFixed(0) : 0} / 100 %`}
                        </div>
                    )}
                    {!!invalidQuestionsQuantity && (
                        <div className={css.invalidQuestions}>{`${indicatorLabel} ${invalidQuestionsQuantity}`}</div>
                    )}
                    {time && (
                        <div className={css.time} style={colorStyle}>
                            <QuestionTimer />
                            <span>{time}</span>
                        </div>
                    )}
                </div>
                {percentIsNumber && (
                    <div className={css.indicator} style={style1}>
                        <div className={css.filledIndicator} style={style2} />
                    </div>
                )}
            </div>
        );
    }
);
SurveyIndicator.displayName = 'SurveyIndicator';
