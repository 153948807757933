import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';

import { setHtml } from '@webapp/common/lib/ui';
import { AnswerType, DropdownType } from '@webapp/common/resources/survey';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { StyledDropdown } from '@webapp/ui/lib/styled-dropdown';
import { CssUiComponent } from '@webapp/ui/lib/survey-custom';
import { Textfield } from '@webapp/ui/lib/textfield';

import type { MatrixCell } from '../matrix-layout/matrix-cell';
import cssLayout from '../matrix-layout/matrix-layout.css';

import css from './matrix-dropdown.css';

export const Group = observer<MatrixCell>(
    ({
        answer: { id: answerId },
        group,
        height,
        question: {
            params: { answers: questionAnswers, dropdownListType, excludeSelectedAnswer }
        }
    }) => {
        const { inputStyle, textStyle } = useTextStyle();
        const selfStyle = useMemo(() => ({ ...inputStyle, maxWidth: undefined, width: undefined }), [inputStyle]);
        const handleChangeValue = useCallback(
            (value) => {
                if (group.type === AnswerType.SELF) {
                    group.changeResponseExtra(answerId, value);
                } else {
                    group.changeResponseValue(answerId, value);
                }
            },
            [answerId, group]
        );
        const { id, name, type } = group;
        const response = group.getResponse(answerId);
        const value = (response?.value || '') as string;
        const extra = (response?.extra || '') as string;
        let answers = dropdownListType === DropdownType.DIFFERENT ? group.answers.toJSON() : questionAnswers;
        const self = type === AnswerType.SELF;

        if (excludeSelectedAnswer) {
            const responsesValues = group.getResponsesValues();
            answers = answers.filter((a) => (value && value === a) || !responsesValues.includes(a));
        }

        return (
            <div className={cn(CssUiComponent.CELL, cssLayout.cell, css.answer)} key={id} style={{ height }}>
                <div className={css.field}>
                    <div className={cn(CssUiComponent.LABEL, css.name)} style={textStyle} {...setHtml(name)} />
                    <div className={css.text}>
                        {self ? (
                            <Textfield
                                transparent
                                rootClassName={cssLayout.control}
                                style={selfStyle}
                                value={extra}
                                onChange={handleChangeValue}
                            />
                        ) : (
                            <StyledDropdown
                                searchable
                                className={cssLayout.control}
                                options={answers}
                                value={value}
                                onChange={handleChangeValue}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
);
Group.displayName = 'Group';
