import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';

import { isEmail, setHtml } from '@webapp/common/lib/ui';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';
import { Textfield } from '@webapp/ui/lib/textfield';

import css from './email.css';

export const Email = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            invalid,
            survey: {
                info: {
                    params: {
                        alert: { email }
                    }
                }
            }
        }
    }) => {
        const { text: textStyle } = useContext(CustomStylesCtx);
        const withLabel = answers.some(({ value: [label] }) => !!label);
        const gridStyle: CSSProperties = useMemo(
            () => ({
                gridTemplateColumns: withLabel ? 'fit-content(30ch) 3fr' : 'auto'
            }),
            [withLabel]
        );

        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.EMAIL, css.list)} style={gridStyle}>
                {answers.map(({ id, response: { change, value: responseValue }, value: [label] }) => {
                    const showError = !isEmail(responseValue as string) && invalid;

                    return (
                        <div className={cn(CssPageBlock.ANSWER, css.answer)} key={id}>
                            {withLabel && (
                                <div
                                    className={cn(CssUiComponent.LABEL, css.label)}
                                    style={textStyle}
                                    {...setHtml(label as string)}
                                />
                            )}
                            <div className={cn(CssUiComponent.INPUT_WRAP, css.field)}>
                                <Textfield
                                    transparent
                                    className={css.control}
                                    // type={TextfieldType.EMAIL}
                                    value={(responseValue as string) || ''}
                                    onChange={(v: any): void => void change(v)}
                                />
                            </div>
                            {showError && <div className={cn(CssUiComponent.INVALID, css.requiredText)}>{email}</div>}
                        </div>
                    );
                })}
            </div>
        );
    }
);
Email.displayName = 'Email';
