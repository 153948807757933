import { useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const Portal: FC<{
    elementId?: string;
    visible?: boolean;
}> = ({ children, elementId = 'popper', visible = true }) => {
    const [container, setContainer] = useState<HTMLElement>(null);
    const [elem, setElem] = useState<HTMLDivElement>(null);

    useLayoutEffect(() => {
        setContainer(document.getElementById(elementId));
        setElem(document.createElement('div'));

        return () => {
            setContainer(null);
            setElem(null);
        };
    }, [elementId]);

    useLayoutEffect(() => {
        container && container.appendChild(elem);

        return () => {
            container && container.removeChild(elem);
        };
    }, [container, elem]);

    useEffect(() => {
        if (elem) {
            elem.style.visibility = visible ? 'visible' : 'hidden';
        }
    }, [elem, visible]);

    return elem && container && createPortal(children, elem);
};
