import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useLayoutEffect, useState } from 'react';

import { useMobile } from '@webapp/common/hooks/use-mobile';
import { isBrowser } from '@webapp/common/lib/const';
import type { ISurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';

import css from './auth.css';

const VK_API_URL = 'https://vk.com/js/api/openapi.js?169';

declare global {
    interface Window {
        vkAsyncInit: () => void;
        VK: {
            init: ({ apiId, onlyWidgets }: { apiId: number; onlyWidgets: boolean }) => void;
            Widgets: {
                Auth: (id: string, params: OnAuthParams) => void;
            };
        };
    }
}

export interface OnAuthParams {
    onAuth: (data: any) => void;
    width: string | number;
    height: string | number;
}

export interface VkAuthData {
    first_name: string;
    hash: string;
    last_name: string;
    photo: string;
    photo_rec: string;
    session: {
        expire: number;
        mid: number;
        secret: string;
        sid: string;
        sig: string;
    };
    uid: number;
}

export const VkAuth = observer<{
    survey: ISurveyStore;
}>(
    ({
        survey: {
            checkVkAuth,
            info: {
                design: {
                    fonts: { buttonColor }
                }
            },
            start
        }
    }) => {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const domReady = Boolean(isBrowser && window.document && window.document.createElement);
        const [vkApiLoaded, setVkApiLoaded] = useState(false);
        const isMobile = useMobile();

        useLayoutEffect(() => {
            if (domReady) {
                if (!window.VK) {
                    if (!document.getElementById('vk-openapi')) {
                        const script = document.createElement('script');

                        script.type = 'text/javascript';
                        script.id = 'vk-openapi';
                        script.src = VK_API_URL;
                        script.async = true;
                        script.onload = () => {
                            setVkApiLoaded(true);
                        };

                        document.head.appendChild(script);
                    }
                } else if (vkApiLoaded) {
                    window.VK.init({ apiId: 6432590, onlyWidgets: true });
                    window.VK.Widgets.Auth('vk_auth', {
                        width: isMobile ? '80vw' : 300,
                        height: isMobile ? '80vw' : 300,
                        onAuth: ({ first_name, last_name, photo, uid }) => {
                            photo = photo.replace(/&amp;/g, '&');
                            void checkVkAuth(JSON.stringify({ first_name, last_name, photo, uid })).then(() => {
                                void start();
                            });
                        }
                    });
                }
            }
            // eslint-disable-next-line
        }, [window.VK, domReady, vkApiLoaded]);

        return (
            <div className={cn(css.box, css.vk_auth)} style={{ borderColor: buttonColor }}>
                <div id='vk_auth' />
            </div>
        );
    }
);
VkAuth.displayName = 'VkAuth';
