import { observer } from 'mobx-react-lite';

import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import { QuestionType } from '@webapp/common/resources/survey';

import { Agreement } from '../types/agreement';
import { Csi } from '../types/csi';
import { Datetime } from '../types/datetime';
import { Dropdown } from '../types/dropdown';
import { Email } from '../types/email';
import { FileUpload } from '../types/file-upload';
import { GroupFreeAnswers } from '../types/group-free-answers';
import { ListSelect } from '../types/list-select';
import { LongText } from '../types/long-text';
import { MatrixDropdown } from '../types/matrix-dropdown';
import { MatrixRating } from '../types/matrix-rating';
import { MatrixSelect } from '../types/matrix-select';
import { MatrixText } from '../types/matrix-text';
import { Name } from '../types/name';
import { NestedList } from '../types/nested-list';
import { Nps } from '../types/nps';
import { Number } from '../types/number';
import { Phone } from '../types/phone';
import { Rank } from '../types/rank';
import { Residence } from '../types/residence';
import { Scale } from '../types/scale';
import { ScaleDistribute } from '../types/scale-distribute';
import { SelectImage } from '../types/select-image';
import { SemDiff } from '../types/sem-diff';
import { ShortText } from '../types/short-text';
import { Smile } from '../types/smile';
import { Star } from '../types/star';
import { TestText } from '../types/test-text';
import { TextBlock } from '../types/text-block';

export const TypeView = observer<{
    question: ISurveyQuestionModel;
    align: AlignSetting;
}>(({ align, question }) => {
    switch (question.type) {
        case QuestionType.ONE_OF_LIST:
        case QuestionType.FEW_OF_LIST:
        case QuestionType.TEST_ONE_OF_LIST:
        case QuestionType.TEST_FEW_OF_LIST:
            return <ListSelect question={question} />;
        case QuestionType.TEST_TEXT:
            return <TestText question={question} />;
        case QuestionType.SELECT_ONE_IMAGE:
        case QuestionType.SELECT_FEW_IMAGE:
            return <SelectImage question={question} />;
        case QuestionType.DROPDOWN_LIST:
            return <Dropdown question={question} />;
        case QuestionType.STAR:
            return <Star question={question} />;
        case QuestionType.NUMBER:
            return <Number question={question} />;
        case QuestionType.SHORT_TEXT:
            return <ShortText question={question} />;
        case QuestionType.LONG_TEXT:
            return <LongText question={question} />;
        case QuestionType.TEXT_BLOCK:
            return <TextBlock question={question} />;
        case QuestionType.GROUP_FREE_ANSWERS:
            return <GroupFreeAnswers align={align} question={question} />;
        case QuestionType.EMAIL:
            return <Email question={question} />;
        case QuestionType.AGREEMENT:
            return <Agreement question={question} />;
        case QuestionType.NPS:
            return <Nps question={question} />;
        case QuestionType.RANK:
        case QuestionType.TEST_RANK:
            return <Rank question={question} />;
        case QuestionType.SCALE:
            return <Scale question={question} />;
        case QuestionType.SEM_DIFF:
            return <SemDiff question={question} />;
        case QuestionType.DISTRIBUTE_SCALE:
            return <ScaleDistribute question={question} />;
        case QuestionType.DATETIME:
            return <Datetime question={question} />;
        case QuestionType.PHONE:
            return <Phone question={question} />;
        case QuestionType.SMILE:
            return <Smile question={question} />;
        case QuestionType.RESIDENCE:
            return <Residence question={question} />;
        case QuestionType.NESTED_LIST:
            return <NestedList question={question} />;
        case QuestionType.FILE_UPLOAD:
            return <FileUpload question={question} />;
        case QuestionType.NAME:
            return <Name question={question} />;
        case QuestionType.MATRIX_FEW_ANSWERS:
        case QuestionType.MATRIX_SINGLE_ANSWER:
            return <MatrixSelect question={question} />;
        case QuestionType.MATRIX_TEXT_ANSWER:
            return <MatrixText question={question} />;
        case QuestionType.MATRIX_RATING:
            return <MatrixRating question={question} />;
        case QuestionType.CSI:
            return <Csi question={question} />;
        case QuestionType.MATRIX_DROPDOWN_LIST:
            return <MatrixDropdown question={question} />;
        default:
            return null;
    }
});
TypeView.displayName = 'TypeView';
