import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { CheckPasswordStatus } from '@webapp/common/lib/api';
import { setHtml } from '@webapp/common/lib/ui';
import { UnescapeHtml } from '@webapp/common/lib/utils';
import type { ISurveyStore } from '@webapp/common/resources/mst-survey/survey/survey';
import { Button } from '@webapp/ui/lib/button';
import { Password } from '@webapp/ui/lib/password';

import css from './auth.css';

const getErrorMsg = ({
    checkPasswordStatus,
    info: {
        params: {
            main: { passwordWasActivated = 'Пароль уже был использован', wrongPasswordLabel }
        }
    }
}: ISurveyStore): string => {
    let error = null;

    switch (checkPasswordStatus) {
        case CheckPasswordStatus.ERROR:
            error = wrongPasswordLabel;
            break;
        case CheckPasswordStatus.PASSWORD_ALREADY_USED:
            error = passwordWasActivated;
            break;
        default:
    }

    return error ? UnescapeHtml(error) : null;
};

export const PwdAuth: FC<{
    survey: ISurveyStore;
}> = observer(({ survey }) => {
    const {
        checkPassword,
        checkPasswordStatus,
        info: {
            design: {
                fonts: { buttonColor }
            },
            params: {
                main: { passwordLabel }
            }
        },
        start
    } = survey;
    const [password, setPassword] = useState('');
    const error = getErrorMsg(survey);
    const loading = checkPasswordStatus === CheckPasswordStatus.PENDING;

    const submit = useCallback(() => {
        void (async () => {
            if (await checkPassword(password)) {
                start();
            }
        })();
    }, [checkPassword, password, start]);

    return (
        <div className={css.box} style={{ borderColor: buttonColor }}>
            <div className={css.title} {...setHtml(passwordLabel)} />
            <div className={css.form}>
                <Password value={password} onChange={setPassword} onEnter={submit} />
                {error && <div className={css.error} {...setHtml(error)} />}
                <Button fluid color='blue' loading={loading} style={{ backgroundColor: buttonColor }} onClick={submit}>
                    ОК
                </Button>
            </div>
        </div>
    );
});
PwdAuth.displayName = 'PwdAuth';
