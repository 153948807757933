import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import { useMemo } from 'react';

import { CompletePageType, TestCheckStatus } from '@webapp/common/resources/survey';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { AnswerIcon } from '@webapp/ui/lib/misc';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';
import { Textfield } from '@webapp/ui/lib/textfield';

import { ListSelectResults } from 'components/questions/types/list-select';

import css from './test-text.css';

export const TestText: FC<SurveyQuestion> = observer(({ question }) => {
    const {
        answers,
        currentPoints,
        factTime,
        maxPoints,
        response: { change, value },
        survey: {
            info: {
                params: {
                    main: { completePage },
                    tests: { scoreLabel }
                }
            }
        },
        targetTime
    } = question;

    const redirect = [CompletePageType.REDIRECT_TO_WEBSITE, CompletePageType.TO_SURVEY].includes(completePage);
    const showText = typeof maxPoints === 'number' || typeof currentPoints === 'number';
    const showResults = !redirect && Boolean(factTime || targetTime || maxPoints || currentPoints);

    const showTimer = Boolean(factTime || targetTime);
    const { inputStyle, itemsGap } = useTextStyle();

    const listStyle = useMemo(
        () => ({
            gap: itemsGap
        }),
        [itemsGap]
    );

    return (
        <div className={css.testText} style={listStyle}>
            <div className={cn(CssQuestionType.SHORT_TEXT, css.fullWidth)}>
                {showResults || showText ? (
                    <div className={css.checked}>
                        {showResults && (
                            <AnswerIcon
                                checked={true}
                                testCheckStatus={
                                    maxPoints === currentPoints ? TestCheckStatus.VALID : TestCheckStatus.INVALID
                                }
                            />
                        )}
                        {value || ''}
                    </div>
                ) : (
                    <Textfield
                        transparent
                        style={inputStyle}
                        value={(value as string) || ''}
                        onChange={(v: any) => void change(v)}
                    />
                )}
            </div>
            {showResults && (
                <ListSelectResults
                    answers={answers}
                    currentPoints={currentPoints}
                    factTime={factTime}
                    maxPoints={maxPoints}
                    pointsLabel={scoreLabel}
                    showTimer={showTimer}
                    targetTime={targetTime}
                />
            )}
        </div>
    );
});
TestText.displayName = 'ShortText';
