import { useCombobox } from 'downshift';
import type { UseComboboxReturnValue } from 'downshift';
import { useCallback } from 'react';

import { useDeepMemo } from '@webapp/common/hooks/use-deep-memo';

import { getLabel } from './lib';
import type { SelectOption } from './lib';

export const useDownshift = (
    options: Array<SelectOption>,
    selectedItem: SelectOption,
    updateSearch: (v: string) => void
): UseComboboxReturnValue<SelectOption> => {
    // TODO fix keyboard nav
    // highlightedIndex,

    const onInputValueChange = useCallback(
        ({ inputValue }) => updateSearch(inputValue === null ? '' : inputValue),
        [updateSearch]
    );

    const cb = useCombobox<SelectOption>({
        items: options,
        itemToString: (o) => (o ? getLabel(o) : ''),
        selectedItem,
        onInputValueChange
        /*onSelectedItemChange: ({ selectedItem }) => {
            setSelected(selectedItem);
        }*/
    });

    return useDeepMemo(cb);
};
