import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { removeTransparency } from '@webapp/common/lib/color';
import { setHtml } from '@webapp/common/lib/ui';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import { CustomStylesCtx, useTextStyle } from '@webapp/ui/lib/custom-styles';
import { Scrollarea } from '@webapp/ui/lib/scrollarea';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import type { MatrixCell } from './matrix-cell';
import { useFixedHeader } from './use-fixed-header';
import { useMatrixParams } from './use-matrix-params';

import css from './matrix-layout.css';

const Spacer: FC<{
    height: string;
}> = ({ height }) => (
    <div className={cn(CssUiComponent.CELL, css.cell, css.span)} style={{ height }}>
        <div>&nbsp;</div>
    </div>
);

const Labels: FC<{
    withScroll: boolean;
    height: string;
    rowsHeights: Array<number | string>;
    style?: CSSProperties;
    answers: Array<ISurveyQuestionAnswerModel>;
    setLabelRefs: (idx: number) => (el: HTMLElement) => void;
}> = observer(({ answers, height, rowsHeights, setLabelRefs, style, withScroll }) => {
    const { textStyle } = useTextStyle();
    const emptyHeaders = height === '0px';

    return (
        <div
            style={style}
            className={cn(css.rowHeader, {
                [css.withScroll]: withScroll,
                [css.empty]: emptyHeaders
            })}
        >
            <Spacer height={height} />
            {answers.map(({ id, value: [value1] }, idx) => (
                <div
                    className={cn(CssUiComponent.CELL, css.cell, css.label)}
                    key={id}
                    ref={setLabelRefs(idx)}
                    style={{
                        height: rowsHeights[idx]
                    }}
                >
                    <div className={CssUiComponent.LABEL} style={textStyle} {...setHtml(value1 as string)} />
                </div>
            ))}
        </div>
    );
});

export const MatrixLayout = observer<{
    className: string;
    question: ISurveyQuestionModel;
    cell: FC<MatrixCell>;
}>(({ cell: Cell, className, question }) => {
    const { answers, groups } = question;
    const customStyles = useContext(CustomStylesCtx);
    const {
        matrix: { fixedHeader }
    } = customStyles;
    const { itemsGap, listStyle, textStyle } = useTextStyle();
    const headerStyle = useMemo(() => ({ ...textStyle, ...fixedHeader }), [fixedHeader, textStyle]);
    const [withScroll, setWithScroll] = useState(false);
    const { bgRef, headerRef, rootRef } = useFixedHeader({
        backgroundColor: removeTransparency(headerStyle.backgroundColor)
    });
    const contentRef = useRef();
    const { headerHeight, ready, rowsHeight, setLabelRefs } = useMatrixParams(answers, headerRef, contentRef);
    const headerStyleStatic = useMemo(() => ({ ...headerStyle, backgroundColor: undefined }), [headerStyle]);
    const gridStyle = useMemo(
        () => ({
            rowGap: itemsGap
        }),
        [itemsGap]
    );
    const contentStyle = useMemo(
        () => ({ gridTemplateColumns: `repeat(${groups.length}, 1fr)`, ...gridStyle }),
        [gridStyle, groups.length]
    );

    const getAnswerStyle = useCallback(
        (idx: number) => ({
            height: `${rowsHeight[idx]}px`,
            ...listStyle
        }),
        [rowsHeight, listStyle]
    );

    const onScrollResize = useCallback((scroll: any) => {
        const withScroll1 = scroll.withScroll();

        setWithScroll(withScroll1);
    }, []);

    return (
        <div className={cn(CssQuestionType.QUESTION, css.matrix, ready && css.ready, className)} ref={rootRef}>
            <Labels
                answers={answers}
                height={headerHeight}
                rowsHeights={rowsHeight}
                setLabelRefs={setLabelRefs}
                style={gridStyle}
                withScroll={withScroll}
            />
            <Scrollarea className={css.scrollarea} vertical={false} onResize={onScrollResize}>
                <div className={css.table} style={contentStyle}>
                    <div className={cn(css.row, css.headers)} ref={headerRef}>
                        {groups.map(({ id, name }) => (
                            <div
                                className={cn(CssUiComponent.CELL, CssUiComponent.LABEL, css.cell, css.header)}
                                key={id}
                                style={headerStyleStatic}
                                {...setHtml(name || '')}
                            />
                        ))}
                    </div>
                    <div className={css.answers} ref={contentRef}>
                        {answers.map((answer, idx) => {
                            return (
                                <div
                                    key={answer.id}
                                    style={getAnswerStyle(idx)}
                                    className={cn(CssPageBlock.ANSWER, css.row, {
                                        [css.rowInvalid]: !answer.valid
                                    })}
                                >
                                    <div
                                        style={headerStyleStatic}
                                        className={cn(
                                            CssUiComponent.CELL,
                                            css.cell,
                                            css.rowHeaderMobile,
                                            css.label,
                                            css.answer
                                        )}
                                        {...setHtml(answer.value[0] as string)}
                                    />
                                    {groups.map((group) => (
                                        <Cell
                                            answer={answer}
                                            customStyles={customStyles}
                                            group={group}
                                            height={`${rowsHeight[idx]}px`}
                                            key={group.id}
                                            question={question}
                                        />
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Scrollarea>
            <div className={css.bg} ref={bgRef} />
        </div>
    );
});
MatrixLayout.displayName = 'MatrixLayout';
