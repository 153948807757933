import cn from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import ReactRating from 'react-rating';
import tinycolor from 'tinycolor2';

import { useDidMountEffect } from '@webapp/common/hooks/legacy-lifecycle';
import { Scrollarea } from '@webapp/ui/lib/scrollarea';

import { Star } from '../icons';

import css from './rating.css';

export const Rating: FC<{
    color?: string;
    size?: number;
    count?: number;
    disabled?: boolean;
    defaultValue?: number;
    onChange?: (value: number) => void;
    className?: string;
    flexStart?: boolean;
    isEmpty?: boolean;
}> = ({ className, color, count, defaultValue, disabled, flexStart, onChange, size = 1, isEmpty }) => {
    const [innerValue, setInnerValue] = useState(defaultValue);

    const starClassName = useCallback(
        (filled) =>
            cn(css.item, {
                [css.sm]: size === 1,
                [css.md]: size === 2,
                [css.lg]: size === 3,
                [css.filled]: filled
            }),
        [size]
    );

    const handleClick = useCallback((value) => {
        setInnerValue((prev) => (prev === value ? 0 : value));
        const el = document?.activeElement as HTMLInputElement;
        if (el?.blur) {
            // fixes focus handle on mobile
            el?.blur();
        }
    }, []);

    const emptySymbol = useMemo(
        () => <Star className={starClassName(false)} style={{ color: tinycolor(color).toRgbString() }} />,
        [color, starClassName]
    );

    const fullSymbol = useMemo(
        () => <Star className={starClassName(true)} style={{ color: tinycolor(color).toRgbString() }} />,
        [color, starClassName]
    );

    useDidMountEffect(() => {
        onChange(innerValue);
    }, [innerValue, onChange]);

    return (
        <div
            className={cn(css.rating, className, {
                [css.flexStart]: flexStart
            })}
        >
            <Scrollarea vertical={false}>
                <ReactRating
                    className={css.reactRating}
                    emptySymbol={emptySymbol}
                    fullSymbol={isEmpty ? emptySymbol : fullSymbol}
                    initialRating={innerValue}
                    readonly={disabled}
                    stop={count}
                    onChange={setInnerValue}
                    onClick={handleClick}
                />
            </Scrollarea>
        </div>
    );
};
