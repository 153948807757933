import { useContext, useMemo } from 'react';

import { CustomStylesCtx } from './custom-styles';
import type { SelectOption, SelectOptions } from './select';
import { Select } from './select';

export const StyledDropdown: FC<
    {
        onChange: (value) => void;
        value: string | number;
        options: Array<SelectOption>;
        className?: string;
    } & SelectOptions
> = ({ className, onChange, options, value, ...rest }) => {
    const {
        select: { activeItem, arrow, item }
    } = useContext(CustomStylesCtx);
    // TODO merge with useViewCustomStyles
    const itemStyle = useMemo(() => ({ borderTop: item.borderTop }), [item.borderTop]);

    return (
        <Select
            activeItemStyle={activeItem}
            arrowStyle={arrow}
            className={className}
            itemStyle={itemStyle}
            options={options}
            placeholder=''
            size='small'
            value={value}
            onChange={onChange}
            {...rest}
        />
    );
};
