import { useEffect, useRef } from 'react';

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useComponentDidMount = (fn: () => void): void => useEffect(fn, []);

export const useComponentWillMount = (fn: () => void): void => {
    const willMount = useRef(true);
    if (willMount.current) {
        fn();
    }
    useComponentDidMount(() => {
        willMount.current = false;
    });
};

export const useDidMountEffect = (func, deps): void => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
