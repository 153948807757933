import { nop } from '@webapp/common/lib/utils';

import css from './overlay.css';

export const Overlay: FC<{
    style: CSSProperties;
    onClick?: () => void;
}> = ({ onClick, style }) => (
    <div className={css.bg} onClick={onClick || nop}>
        <div className={css.dropdownBg} style={style} />
    </div>
);
