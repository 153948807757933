import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { setHtml } from '@webapp/common/lib/ui';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';
import { replaceDigitsWithMask, replaceDigitsWithX, Textfield, TextfieldType } from '@webapp/ui/lib/textfield';

import css from './phone.css';

const imaskOptions = {
    lazy: true,
    unmask: false
};

function selectAll(this: HTMLInputElement): void {
    this.select();
}

// TODO empirical, calc using canvas
const calcMaskedInputStyle = (mask: string): CSSProperties => {
    const separators = mask.replace(/[0-9+ -]/g, '');
    const numCount = mask.length - separators.length;
    const chLen = numCount + separators.length / 2;

    return {
        width: `calc(48px + ${chLen}ch)`
    };
};

const CompositeInput: FC<{
    response: ISurveyQuestionResponseModel;
    phoneFormatCountry: string;
    phoneFormatNumber: string;
}> = ({ phoneFormatCountry, phoneFormatNumber, response }) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { change, changeExtra, extra, value } = response;
    const codeMask = replaceDigitsWithMask(phoneFormatCountry.replace(/_/g, ''));
    const phoneMask = replaceDigitsWithMask(phoneFormatNumber.replace(/_/g, ''));
    const codePlaceholder = replaceDigitsWithX(phoneFormatCountry.replace(/_/g, ''));
    const phonePlaceholder = replaceDigitsWithX(phoneFormatNumber.replace(/_/g, ''));
    const codeRef = useRef(null);
    const phoneRef = useRef(null);

    const countryStyle = useMemo(() => calcMaskedInputStyle(codeMask), [codeMask]);
    const phoneStyle = useMemo(() => calcMaskedInputStyle(phoneMask), [phoneMask]);

    const onChangePhone = useCallback(
        (value: string) => {
            if (value.length === phoneMask.length) {
                phoneRef.current.blur();
            }
            void change(value);
        },
        [change, phoneMask.length]
    );

    const onChangeCode = useCallback(
        (value: string) => {
            if (value.length === codeMask.length) {
                codeRef.current.blur();
                phoneRef.current.focus();
            }
            void changeExtra(value);
        },
        [changeExtra, codeMask.length]
    );

    useEffect(() => {
        const codeInput = codeRef.current.input();
        const phoneInput = phoneRef.current.input();

        codeInput.addEventListener('focus', selectAll);
        phoneInput.addEventListener('focus', selectAll);

        return () => {
            codeInput.removeEventListener('focus', selectAll);
            phoneInput.removeEventListener('focus', selectAll);
        };
    }, []);

    return (
        <div className={css.fields}>
            <div className={cn(css.field, css.code)} style={countryStyle}>
                <Textfield
                    clearable
                    transparent
                    className={css.control}
                    defaultValue={extra ? String(extra) : undefined}
                    imaskOptions={imaskOptions}
                    mask={codeMask}
                    placeholder={codePlaceholder}
                    ref={codeRef}
                    type={TextfieldType.IMASK_NUMBER}
                    onChange={onChangeCode}
                />
            </div>
            <div className={cn(css.field, css.number)} style={phoneStyle}>
                <Textfield
                    clearable
                    transparent
                    className={css.control}
                    defaultValue={value ? String(value) : undefined}
                    imaskOptions={imaskOptions}
                    mask={phoneMask}
                    placeholder={phonePlaceholder}
                    ref={phoneRef}
                    type={TextfieldType.IMASK_NUMBER}
                    onChange={onChangePhone}
                />
            </div>
        </div>
    );
};

export const Phone = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            params: { phoneFormatCountry, phoneFormatNumber }
        }
    }) => {
        const customStylesValue = useContext(CustomStylesCtx);
        const withTitle = answers.some(({ value: [title] }) => !!title);
        const stylesCtx = useMemo(
            () => ({ ...customStylesValue, textField: { ...customStylesValue.textField, minWidth: 'unset' } }),
            [customStylesValue]
        );
        const gridStyle: CSSProperties = useMemo(
            () => ({
                gridTemplateColumns: withTitle
                    ? 'fit-content(100%) fit-content(10ch) fit-content(30ch)'
                    : 'fit-content(10ch) fit-content(30ch)'
            }),
            [withTitle]
        );

        return (
            <CustomStylesCtx.Provider value={stylesCtx}>
                <div className={cn(CssQuestionType.QUESTION, CssQuestionType.PHONE, css.list)} style={gridStyle}>
                    {answers.map(({ id, response, value: [title] }) => (
                        <div className={cn(CssPageBlock.ANSWER, css.answer)} key={id}>
                            {withTitle && (
                                <div
                                    className={cn(CssUiComponent.LABEL, css.label)}
                                    style={customStylesValue.text}
                                    {...setHtml(title as string)}
                                />
                            )}
                            <CompositeInput
                                phoneFormatCountry={phoneFormatCountry}
                                phoneFormatNumber={phoneFormatNumber}
                                response={response}
                            />
                        </div>
                    ))}
                </div>
            </CustomStylesCtx.Provider>
        );
    }
);
Phone.displayName = 'Phone';
