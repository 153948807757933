import type { Duration } from 'dayjs/plugin/duration';

import { DURATION_FORMAT } from '@webapp/common/lib/date';

import { Clock } from '../icons';
import css from '../point-results/points-results.css';

export const TimeResults: FC<{
    factTime: Duration;
    targetTime: Duration;
}> = ({ factTime, targetTime }) => (
    <div className={css.pointsResults}>
        <Clock />
        <div>{`${factTime.format(DURATION_FORMAT)} / ${targetTime.format(DURATION_FORMAT)}`}</div>
    </div>
);
