import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { DURATION_FORMAT } from '@webapp/common/lib/date';
import { createTextStyle, setHtml } from '@webapp/common/lib/ui';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import { QuestionWithoutHeader } from '@webapp/common/resources/survey';
import { QuestionTimer } from '@webapp/ui/lib/icons';
import { QuestionOrder } from '@webapp/ui/lib/misc';
import { CssPageBlock } from '@webapp/ui/lib/survey-custom';

import { styleToQuestionPosition } from '../lib';
import css from '../question.css';

export const Header = observer<{
    mark: ReactNode;
    question: ISurveyQuestionModel;
    readOnly: boolean;
    showOrder: boolean;
    showRequired: boolean;
    withError: boolean;
}>(
    ({
        mark,
        question: {
            order,
            params: { timer: paramTimer },
            survey: {
                info: {
                    design: {
                        fonts: {
                            elementColor,
                            questionTextBold,
                            questionTextColor,
                            questionTextItalic,
                            questionTextSize,
                            questionTextThrough,
                            questionTextUnderline
                        },
                        view: { questionBlockPosition }
                    }
                }
            },
            timerLeft,
            title,
            type
        },
        readOnly,
        showOrder,
        showRequired,
        withError
    }) => {
        const showTitle = !QuestionWithoutHeader.has(type);
        const questionPositionClass = questionBlockPosition ? styleToQuestionPosition[questionBlockPosition] : '';
        const headerTitleStyles: CSSProperties = createTextStyle({
            underline: questionTextUnderline,
            through: questionTextThrough,
            bold: questionTextBold,
            italic: questionTextItalic,
            color: questionTextColor,
            size: questionTextSize
        });
        const showTimer = Boolean(!readOnly && paramTimer);

        const timer = timerLeft.format(DURATION_FORMAT);

        const orderStyle: CSSProperties = useMemo(
            () => ({
                color: elementColor
            }),
            [elementColor]
        );

        return (
            <div className={cn(CssPageBlock.QUESTION_HEADER, css.questionHeader, { [css.withTimer]: paramTimer })}>
                {showTitle && (
                    <div className={cn(css.header, questionPositionClass)} style={headerTitleStyles}>
                        {showOrder && <QuestionOrder invalid={withError} order={order} style={orderStyle} />}
                        <div className={cn(CssPageBlock.QUESTION_TITLE, css.title)}>
                            <span className={css.inner} {...setHtml(title)} />
                            {showRequired && <span className={css.requiredTitle}>{mark}</span>}
                        </div>
                        {showTimer && (
                            <div className={cn(CssPageBlock.QUESTION_TIMER, css.timer)} style={orderStyle}>
                                <QuestionTimer />
                                <span>{timer}</span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
);
Header.displayName = 'Header';
