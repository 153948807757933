import { setHtml } from '@webapp/common/lib/ui';

import { DoubleMark } from '../icons';

import css from './points-results.css';

export const PointsResults: FC<{
    fact: number;
    max: number;
    pointsLabel?: string;
    factOnly?: boolean;
}> = ({ fact, factOnly, max, pointsLabel = 'баллов' }) => (
    <div className={css.pointsResults}>
        <DoubleMark />
        <div>
            {factOnly ? fact : `${fact}/${max}`} {<span className={css.pointsLabel} {...setHtml(pointsLabel)} />}
        </div>
    </div>
);
