import cn from 'classnames';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useEsc } from '@webapp/common/hooks/use-esc';
import { useWindowSize } from '@webapp/common/hooks/use-window-size';

import { Checkbox } from '../checkbox';
import { CustomStylesCtx } from '../custom-styles';
import { Cross } from '../icons';
import { CssUiComponent } from '../survey-custom';

import css from './inline-slider.css';

export interface ImgInfo {
    url: string;
    width: number;
    height: number;
}

const Slide: FC<{
    img: ImgInfo;
    multiple: boolean;
    onChange: (checked: boolean) => void;
    checked: boolean;
}> = ({ checked, img, multiple, onChange }) => (
    <div className={css.slide}>
        <div className={css.imgWrap}>
            <img alt='image' height={img.height} src={img.url} width={img.width} onClick={() => onChange(!checked)} />
            <Checkbox checked={checked} className={css.checkbox} round={!multiple} onChange={onChange} />
        </div>
    </div>
);

export const ImageSlider: FC<{
    multiple?: boolean;
    slides: Array<{
        id: number;
        img: ImgInfo;
        checked?: boolean;
    }>;
    onSelect: (id: number, value: boolean) => void;
    onClose?: () => void;
    initialImg?: number;
}> = ({ initialImg = 0, multiple, onClose, onSelect, slides }) => {
    const many = slides.length > 1;
    const slidesRef = useRef(null);
    const [ready, setReady] = useState(false);
    const [curSlide, setCurSlide] = useState(initialImg);
    const [innerWidth] = useWindowSize();
    const {
        imageSlider: {
            root: { color }
        }
    } = useContext(CustomStylesCtx);

    const crossStyle = useMemo(() => ({ stroke: color, color }), [color]);
    const arrowStyle = useMemo(() => ({ borderColor: color }), [color]);
    const slidesStyle = useMemo(() => {
        if (!slidesRef.current) return {};
        const { width } = slidesRef.current.getBoundingClientRect();
        return {
            width: `calc(${slides.length} * ${width}px)`,
            transform: `translateX(${-width * curSlide}px)`
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerWidth, curSlide, slides.length, slidesRef.current]);

    const prev = useCallback(() => setCurSlide((c) => (c === 0 ? slides.length - 1 : c - 1)), [slides.length]);
    const next = useCallback(() => setCurSlide((c) => (c === slides.length - 1 ? 0 : c + 1)), [slides.length]);

    const handleCheck = useCallback(
        (id: number) => (checked: boolean) => {
            onSelect(id, checked);
        },
        [onSelect]
    );

    useEsc(onClose);

    useEffect(() => {
        if (!slidesRef.current) return;
        setReady(false);
        setCurSlide(initialImg);
        setTimeout(() => setReady(true), 100);
        // eslint-disable-next-line
    }, [initialImg, slidesRef.current]);

    return (
        <div className={cn(CssUiComponent.MODAL, css.imageSlider)}>
            <Cross className={css.close} style={crossStyle} onClick={onClose} />
            {many && <div className={css.leftIcon} style={arrowStyle} onClick={prev} />}
            <div className={cn(css.slides, ready && css.ready)} ref={slidesRef}>
                <div className={css.slidesInner} style={slidesStyle}>
                    {slides.map(({ checked, id, img }) => (
                        <Slide checked={checked} img={img} key={id} multiple={multiple} onChange={handleCheck(id)} />
                    ))}
                </div>
            </div>
            {many && <div className={css.rightIcon} style={arrowStyle} onClick={next} />}
        </div>
    );
};
