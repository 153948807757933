import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useMemo } from 'react';

import { setHtml } from '@webapp/common/lib/ui';
import type { ISurveyQuestionGroupModel } from '@webapp/common/resources/mst-survey/question_group';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { CustomStylesCtx, useTextStyle } from '@webapp/ui/lib/custom-styles';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import css from './sem-diff.css';

const SemDiffGroup: FC<{
    answerId: number;
    group: ISurveyQuestionGroupModel;
    borderColor: string;
    textStyle: CSSProperties;
}> = observer(({ answerId, borderColor, group, textStyle }) => {
    const response = group.getResponse(answerId);
    const selected = (response?.value || false) as boolean;

    const handleSelect = useCallback(
        (group: ISurveyQuestionGroupModel, value: boolean) => () => {
            group.changeResponseValue(answerId, value);
            group.changeResponseExtra(answerId, group.name);
        },
        [answerId]
    );

    return (
        <div
            className={cn(css.item, { [css.selected]: selected })}
            key={group.id}
            style={
                selected
                    ? {
                          borderColor,
                          ...textStyle
                      }
                    : textStyle
            }
            onClick={handleSelect(group, !selected)}
        >
            {group.name}
        </div>
    );
});

const SemDiffAnswer: FC<{
    id: number;
    answerStyle: CSSProperties;
    textStyle: CSSProperties;
    value1: any;
    valid: any;
    labelStyle: CSSProperties;
    borderColor: string;
    value2: any;
    groups: any;
    value3: any;
}> = observer(({ answerStyle, borderColor, groups, id, labelStyle, textStyle, valid, value1, value2, value3 }) => (
    <div className={cn(CssPageBlock.ANSWER, css.answer)} style={answerStyle}>
        <div className={css.name} style={textStyle} {...setHtml(value1)} />
        <div
            className={cn(css.field, {
                [css.field_invalid]: valid === false
            })}
        >
            <div className={cn(CssUiComponent.LABEL, css.label)} style={labelStyle} {...setHtml(value2)} />
            <div className={css.scale}>
                {groups.map((group) => (
                    <SemDiffGroup
                        answerId={id}
                        borderColor={borderColor}
                        group={group}
                        key={group.id}
                        textStyle={textStyle}
                    />
                ))}
            </div>
            <div className={cn(CssUiComponent.LABEL, css.label)} style={labelStyle} {...setHtml(value3)} />
        </div>
    </div>
));

export const SemDiff = observer<SurveyQuestion>(({ question: { answers, groups } }) => {
    const { answerStyle } = useTextStyle();
    const { text: textStyle } = useContext(CustomStylesCtx);
    const computedFontSize = Math.max(parseInt(`${textStyle.fontSize}`) * 0.75, 12);

    const labelStyle: CSSProperties = useMemo(
        () => ({
            ...textStyle,
            fontSize: isNaN(computedFontSize) ? textStyle.fontSize : `${computedFontSize}px`
        }),
        [computedFontSize, textStyle]
    );

    return (
        <div className={cn(CssQuestionType.QUESTION, CssQuestionType.SEM_DIFF, css.diff)}>
            {answers.map(({ id, valid, value: [value1, value2, value3] }) => (
                <SemDiffAnswer
                    answerStyle={answerStyle}
                    borderColor={answerStyle.color}
                    groups={groups}
                    id={id}
                    key={id}
                    labelStyle={labelStyle}
                    textStyle={textStyle}
                    valid={valid}
                    value1={value1}
                    value2={value2}
                    value3={value3}
                />
            ))}
        </div>
    );
});
SemDiff.displayName = 'SemDiff';
