import cn from 'classnames';
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';

import { PLACEHOLDER } from '@webapp/account/src/resources/questions/fillers';
import { nop } from '@webapp/common/lib/utils';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';
import { CssUiComponent } from '@webapp/ui/lib/survey-custom';

import {
    blocks,
    DateMasked,
    definitions,
    // emailMask,
    EmailMasked,
    NumericMasked,
    TextfieldType,
    UrlBlocks,
    UrlMasked
} from './lib';
import type { ITextfield, TextFieldProps } from './lib';

import css from './textfield.css';

// TODO use hook https://github.com/uNmAnNeR/imaskjs/blob/master/packages/react-imask/README.md#using-hook
export const Textfield = forwardRef<ITextfield, TextFieldProps>(
    (
        {
            clearable,
            rootClassName,
            type = TextfieldType.STRING,
            placeholder = '',
            onChange = nop,
            className,
            mask, // events not fired if empty
            pattern,
            min,
            max,
            error,
            transparent,
            onEnter,
            defaultValue,
            imaskOptions,
            icon: Icon,
            iconPos = 'start',
            iconClick,
            ...commonProps
        },
        inputRef
    ) => {
        const innerRef = useRef<any>(null);
        const { textField: customStyles } = useContext(CustomStylesCtx);
        const inputStyle: CSSProperties = useMemo(
            () => ({ ...customStyles, ...commonProps.style }),
            [commonProps.style, customStyles]
        );
        const [maskedValue, setMaskedValue] = useState(
            type === TextfieldType.IMASK_IRRATIONAL && defaultValue ? String(defaultValue) : defaultValue
        );

        const handleMaskedChange = useCallback(
            (value, _masked, _ev) => {
                const fireChange = !!value || clearable;
                if (fireChange && maskedValue !== value) {
                    setMaskedValue(value);
                    onChange(value);
                }
            },
            [clearable, maskedValue, onChange]
        );

        const handleMaskedDateChange = useCallback(
            (value, _masked, _ev) => {
                if (value) {
                    return onChange({
                        target: { value }
                    });
                }
            },
            [onChange]
        );

        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const target = e.target || e.currentTarget;
                if (target) {
                    let value: string | number = target.value;
                    value = value && type === TextfieldType.NUMBER ? Number(value) : value;
                    e.persist();
                    return onChange(value, e);
                }
            },
            [onChange, type]
        );

        const handleEnter = useCallback(
            (event: any) => {
                if (onEnter && event.key === 'Enter') {
                    onEnter(event);
                }
            },
            [onEnter]
        );

        const iconCN = Icon && (iconPos === 'start' ? cn(css.withIcon, css.iconStart) : cn(css.withIcon, css.iconEnd));

        const controlClassName = useMemo(
            () =>
                cn(css.control, className, {
                    [css.error]: error,
                    [css.transparent]: transparent
                }),

            [className, error, transparent]
        );

        useEffect(() => {
            setMaskedValue(
                type === TextfieldType.IMASK_IRRATIONAL && defaultValue ? String(defaultValue) : defaultValue
            );
        }, [defaultValue]);

        useImperativeHandle<ITextfield, ITextfield>(
            inputRef,
            (): ITextfield => ({
                focus: () => {
                    innerRef.current?.focus();
                },
                blur: () => {
                    innerRef.current?.blur();
                },
                select: () => {
                    innerRef.current?.select();
                },
                input: () => innerRef.current
            })
        );

        const control = (() => {
            switch (type) {
                case TextfieldType.MULTILINE:
                    return (
                        <textarea
                            className={cn(css.multiline, controlClassName)}
                            defaultValue={defaultValue}
                            ref={innerRef}
                            onKeyDown={handleEnter}
                            {...commonProps}
                            style={inputStyle}
                            onChange={handleChange}
                        />
                    );
                case TextfieldType.NUMBER:
                    return (
                        <input
                            className={controlClassName}
                            defaultValue={defaultValue}
                            max={max}
                            min={min}
                            ref={innerRef}
                            type='number'
                            onKeyDown={handleEnter}
                            {...commonProps}
                            style={inputStyle}
                            onChange={handleChange}
                        />
                    );
                case TextfieldType.DATE:
                    return (
                        <DateMasked
                            autofix={true}
                            blocks={blocks}
                            className={controlClassName}
                            definitions={definitions}
                            mask={mask}
                            pattern={mask as string}
                            placeholder={placeholder}
                            unmask={false}
                            value={maskedValue}
                            onAccept={handleMaskedDateChange}
                            onComplete={handleMaskedDateChange}
                            onKeyDown={handleEnter}
                            {...(commonProps as any)}
                            style={inputStyle}
                        />
                    );
                default: {
                    if (type === TextfieldType.IMASK_IRRATIONAL) {
                        return (
                            <IMaskInput
                                blocks={blocks}
                                className={controlClassName}
                                definitions={definitions}
                                lazy={!!placeholder}
                                mapToRadix={[',', ';', 'ю', 'Ю']}
                                max={max}
                                min={min}
                                placeholder={placeholder}
                                radix={'.'}
                                ref={innerRef}
                                scale={1}
                                type={type}
                                unmask={true}
                                value={maskedValue}
                                onComplete={handleMaskedChange}
                                onKeyDown={handleEnter}
                                {...commonProps}
                                mask={Number}
                                style={inputStyle}
                                {...(imaskOptions as any)}
                            />
                        );
                    }

                    if (type === TextfieldType.URL) {
                        return (
                            <UrlMasked
                                protocol
                                blocks={UrlBlocks}
                                className={controlClassName}
                                lazy={true}
                                mask='{https://}url'
                                overwrite={true}
                                placeholder={placeholder || 'https://example.com'}
                                ref={innerRef}
                                unmask={true}
                                value={maskedValue}
                                onAccept={handleMaskedChange}
                                onChange={onChange}
                                onKeyDown={handleEnter}
                                {...(commonProps as any)}
                                style={inputStyle}
                                {...imaskOptions}
                            />
                        );
                    }

                    if (type === TextfieldType.DOMAIN) {
                        return (
                            <UrlMasked
                                blocks={UrlBlocks}
                                className={controlClassName}
                                lazy={true}
                                mask='url'
                                overwrite={true}
                                pattern='*'
                                placeholder={placeholder || 'ama'}
                                ref={innerRef}
                                unmask={true}
                                value={maskedValue}
                                onAccept={handleMaskedChange}
                                onChange={onChange}
                                onKeyDown={handleEnter}
                                {...(commonProps as any)}
                                style={inputStyle}
                                {...imaskOptions}
                            />
                        );
                    }

                    if (type === TextfieldType.EMAIL) {
                        return (
                            <EmailMasked
                                className={controlClassName}
                                lazy={true}
                                // mask={emailMask}
                                overwrite={true}
                                ref={innerRef}
                                unmask={true}
                                onAccept={handleMaskedChange}
                                onChange={handleChange}
                                onKeyDown={handleEnter}
                                {...(commonProps as any)}
                                placeholder={placeholder || PLACEHOLDER.EMAIL}
                                style={inputStyle}
                                value={maskedValue}
                            />
                        );
                    }

                    if (!mask && !pattern) {
                        return (
                            <input
                                autoComplete={type === TextfieldType.PASSWORD ? 'new-password' : 'off'}
                                className={controlClassName}
                                defaultValue={defaultValue}
                                placeholder={placeholder}
                                ref={innerRef}
                                type={type}
                                onChange={handleChange}
                                onKeyDown={handleEnter}
                                {...commonProps}
                                style={inputStyle}
                            />
                        );
                    }

                    if (type === TextfieldType.IMASK_NUMBER) {
                        return (
                            <NumericMasked
                                blocks={blocks}
                                className={controlClassName}
                                definitions={definitions}
                                inputRef={(el) => (innerRef.current = el)}
                                lazy={!!placeholder}
                                mask={mask}
                                max={max}
                                min={min}
                                pattern={(pattern || mask) as string}
                                placeholder={placeholder}
                                type={type}
                                unmask={false}
                                value={maskedValue}
                                onAccept={handleMaskedChange}
                                onKeyDown={handleEnter}
                                {...(commonProps as any)}
                                style={inputStyle}
                                {...imaskOptions}
                            />
                        );
                    }

                    return (
                        <IMaskInput
                            blocks={blocks}
                            className={controlClassName}
                            definitions={definitions}
                            inputRef={(el) => (innerRef.current = el)}
                            lazy={!!placeholder}
                            mask={mask}
                            max={max}
                            min={min}
                            pattern={(pattern || mask) as string}
                            placeholder={placeholder}
                            type={type}
                            unmask={false}
                            value={maskedValue}
                            onAccept={handleMaskedChange}
                            onKeyDown={handleEnter}
                            {...(commonProps as any)}
                            style={inputStyle}
                            {...imaskOptions}
                        />
                    );
                }
            }
        })();

        return (
            <div className={cn(CssUiComponent.INPUT, css.textfield, rootClassName, iconCN)}>
                {Icon && (
                    <div className={css.icon} onClick={iconClick}>
                        <Icon />
                    </div>
                )}
                {control}
            </div>
        );
    }
);

Textfield.displayName = 'Textfield';
