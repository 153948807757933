import isEqual from 'lodash/isEqual';
import { useRef } from 'react';

export const useDeepMemo = <T>(v: T): T => {
    const prev = useRef(v);

    if (isEqual(prev.current, v)) {
        return prev.current;
    }

    prev.current = v;
    return v;
};
