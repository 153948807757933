import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { AnswerType, CompletePageType, QuestionType, TestCheckStatus } from '@webapp/common/resources/survey';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { PointsResults } from '@webapp/ui/lib/point-results/points-results';
import { CssQuestionType } from '@webapp/ui/lib/survey-custom';
import { TimeResults } from '@webapp/ui/lib/time-results/time-results';

import { ListSelectAnswer } from './list-select-answer';

import css from './list-select.css';

const singleTypes = new Set([QuestionType.ONE_OF_LIST, QuestionType.TEST_ONE_OF_LIST]);

ListSelectAnswer.displayName = 'Answer';

export const ListSelectResults: FC<{
    showTimer: any;
    factTime: any;
    targetTime: any;
    maxPoints: any;
    currentPoints: any;
    answers: any;
    pointsLabel: any;
}> = ({ answers, currentPoints, factTime, maxPoints, pointsLabel, showTimer, targetTime }) => {
    const factOnly = answers.every((answer) => answer.testCheckStatus !== TestCheckStatus.VALID);
    return (
        <div className={css.results}>
            {(maxPoints || currentPoints) && (
                <PointsResults fact={currentPoints} factOnly={factOnly} max={maxPoints} pointsLabel={pointsLabel} />
            )}
            {showTimer && <TimeResults factTime={factTime} targetTime={targetTime} />}
        </div>
    );
};

export const ListSelect: FC<SurveyQuestion> = observer(({ question }) => {
    const {
        answers,
        currentPoints,
        factTime,
        isTestCheckedQuestion,
        maxPoints,
        params: { randomOrder, required, questionColumns },
        survey: {
            info: {
                params: {
                    main: { completePage },
                    tests: { scoreLabel }
                }
            }
        },
        targetTime,
        type,
        id
    } = question;
    const multiple = !singleTypes.has(type);
    const redirect = [CompletePageType.REDIRECT_TO_WEBSITE, CompletePageType.TO_SURVEY].includes(completePage);
    const showResults = !redirect && Boolean(factTime || targetTime || maxPoints || currentPoints);
    const showTimer = Boolean(factTime || targetTime);
    const { itemsGap } = useTextStyle();

    const listStyle = useMemo(
        () => ({
            gap: itemsGap,
            display: questionColumns ? 'block' : undefined,
            columns: questionColumns ? questionColumns : undefined
        }),
        [itemsGap]
    );

    const sortedAnswers = useMemo(
        () =>
            randomOrder
                ? answers.slice().sort((a, b) => {
                      if ((a.type === AnswerType.SELF || a.exception) && b.type !== AnswerType.SELF && !b.exception)
                          return 1;
                      if (a.type !== AnswerType.SELF && !a.exception && (b.type === AnswerType.SELF || b.exception))
                          return -1;
                      return 0;
                  })
                : answers,
        [answers, randomOrder]
    );

    return (
        <div
            className={cn(
                CssQuestionType.QUESTION,
                CssQuestionType.LIST_SELECT,
                css.list,
                questionColumns && questionColumns !== 1 && css.listWithColumns
            )}
            style={listStyle}
        >
            {sortedAnswers.map((answer) => (
                <ListSelectAnswer
                    answer={answer}
                    isTestCheckedQuestion={isTestCheckedQuestion}
                    key={answer.id}
                    multiple={multiple}
                    questionId={id}
                    required={required}
                    showResults={showResults}
                />
            ))}
            {showResults && (
                <ListSelectResults
                    answers={answers}
                    currentPoints={currentPoints}
                    factTime={factTime}
                    maxPoints={maxPoints}
                    pointsLabel={scoreLabel}
                    showTimer={showTimer}
                    targetTime={targetTime}
                />
            )}
        </div>
    );
});
ListSelect.displayName = 'ListSelect';
