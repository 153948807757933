import type { CSSProperties } from 'react';

export const getChildrenBoxes = (el: HTMLElement): Array<DOMRect> =>
    el ? Array.from(el.children).map((c) => c.getBoundingClientRect()) : [];

export const calcHeight = (boxes: Array<DOMRect>): string => {
    if (boxes.length > 0) {
        let maxHeight: string | number = 0;
        for (const { height } of boxes) {
            if (height > maxHeight) {
                maxHeight = height;
            }
        }
        maxHeight = maxHeight ? `${maxHeight}px` : 'auto';

        return maxHeight;
    }

    return null;
};

export const applyStylesToElement = (element: HTMLElement, styles: CSSProperties, unset: boolean): void => {
    Object.keys(styles).forEach((key) => {
        element.style[key] = unset ? null : styles[key];
    });
};

export const applyToChildren = (root: HTMLElement, styles: CSSProperties, unset: boolean): void => {
    Array.from(root.children).forEach((c: HTMLElement) => {
        applyStylesToElement(c, styles, unset);
    });
};
