import cn from 'classnames';
import { isValidElement, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { setHtml } from '@webapp/common/lib/ui';
import { nop } from '@webapp/common/lib/utils';

import { CustomStylesCtx } from '../custom-styles';
import { CheckMark } from '../icons';
import { CssUiComponent } from '../survey-custom';

import css from './checkbox-dumb.css';

export const CheckboxDumb: FC<{
    label?: ReactNode;
    labelClassName?: string;
    className?: string;
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
    round?: boolean;
    style?: CSSProperties;
    onChange?: (checked: boolean) => void;
}> = ({ onChange = nop, defaultChecked, checked, className, disabled, label, labelClassName, round }) => {
    const prev = useRef({
        checked,
        defaultChecked
    });
    const {
        checkbox: { checked: checkedStyle, label: labelStyle, root }
    } = useContext(CustomStylesCtx);

    const handleChange = (): void => {
        const next = !checked;
        if (!disabled) {
            onChange(next);
        }
    };

    const style = useMemo(
        () =>
            Object.assign(
                {},
                root,
                checked ? checkedStyle : {},
                round ? {} : { width: checkedStyle?.height || root?.height },
                { fontSize: labelStyle.fontSize }
            ),
        [checkedStyle, checked, labelStyle.fontSize, root, round]
    );

    const markStyle = useMemo(
        () => ({
            color: checkedStyle?.color
        }),
        [checkedStyle?.color]
    );

    return (
        <div className={cn(css.checkbox, CssUiComponent.CHECKBOX, className)} onClick={handleChange}>
            <div
                style={style}
                className={cn(css.control, CssUiComponent.CHECKBOX_MARK, {
                    [css.round]: round,
                    [css.disabled]: disabled,
                    [css.checked]: checked,
                    [CssUiComponent.CHECKBOX_SELECTED]: checked
                })}
            >
                <CheckMark className={cn({ [css.visible]: checked })} style={markStyle} />
            </div>

            {label &&
                (isValidElement(label) ? (
                    <div className={cn(css.label, labelClassName)} style={labelStyle}>
                        {label}
                    </div>
                ) : (
                    <div className={cn(css.label, labelClassName)} {...setHtml(String(label))} style={labelStyle} />
                ))}
        </div>
    );
};
CheckboxDumb.displayName = 'Checkbox';
