import { useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'throttle-debounce';
import { useImmer } from 'use-immer';

import { useWindowSize } from '@webapp/common/hooks/use-window-size';
import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';

import { calcHeight, getChildrenBoxes } from './lib';

export const useMatrixParams = (
    answers: Array<ISurveyQuestionAnswerModel>,
    headerRef: MutableRefObject<any>,
    rowsRef: MutableRefObject<any>
): {
    headerHeight: string;
    rowsHeight: Array<number>;
    setLabelRefs: (idx: number) => (el: HTMLElement) => void;
    ready: boolean;
} => {
    const [headerHeight, setHeaderHeight] = useImmer('auto');
    const [ready, setReady] = useImmer(false);
    const [windowWidth] = useWindowSize();
    const [rowsHeight, setRowsHeight] = useImmer(() => Array(answers.length).fill('auto'));
    const callbacks = useMemo(
        () =>
            Array(answers.length)
                .fill(0)
                .reduce(
                    (acc, _, idx) => (
                        acc.push(
                            debounce(500, (el: HTMLElement) => {
                                if (el) {
                                    const { height } = el.getBoundingClientRect();
                                    setRowsHeight((draft) => {
                                        if (draft[idx] !== height) {
                                            draft[idx] = height;
                                        }
                                    });
                                } else {
                                    setRowsHeight((draft) => {
                                        if (draft[idx] !== 'auto') {
                                            draft[idx] = 'auto';
                                        }
                                    });
                                }
                            })
                        ),
                        acc
                    ),
                    []
                ),
        [answers.length, setRowsHeight]
    );

    const setLabelRefs = useCallback((idx) => callbacks[idx], [callbacks]);

    const adjust = useMemo(
        () =>
            debounce(100, (header, rows) => {
                setReady(false);
                requestAnimationFrame(() => {
                    if (header) {
                        const childrenBoxes = getChildrenBoxes(header);
                        const h = calcHeight(childrenBoxes);

                        setHeaderHeight(h);
                    }
                    if (rows) {
                        const rowsHeight = [];
                        for (const r of rows.children) {
                            const [, ...contentBoxes] = getChildrenBoxes(r); // skip mobile view row
                            const h = calcHeight(contentBoxes);
                            const num = parseFloat(h);
                            rowsHeight.push(num);
                        }
                        setRowsHeight((draft) => {
                            draft.forEach((r, idx) => {
                                if (r === 'auto') {
                                    return rowsHeight[idx];
                                } else {
                                    return Math.max(r, rowsHeight[idx]);
                                }
                            });
                        });
                    }
                    setReady(true);
                });
            }),
        [setHeaderHeight, setReady, setRowsHeight]
    );

    useEffect(() => {
        const header = headerRef.current;
        const headerResizeObserver = new ResizeObserver(() => {
            adjust(header, rowsRef.current);
        });

        headerResizeObserver.observe(header);

        adjust(header, rowsRef.current);

        return () => {
            headerResizeObserver.unobserve(header);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerRef.current, windowWidth]);

    return useMemo(
        () => ({
            ready,
            headerHeight,
            rowsHeight,
            setLabelRefs
        }),
        [headerHeight, rowsHeight, setLabelRefs, ready]
    );
};
