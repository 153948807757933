import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';

import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import { RESIDENCE_TYPE_LABEL } from '@webapp/common/resources/survey';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { useTextStyle } from '@webapp/ui/lib/custom-styles';
import { StyledDropdown } from '@webapp/ui/lib/styled-dropdown';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import css from './residence.css';

export const Residence = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            survey: { getResidences, residence }
        }
    }) => {
        const { answerStyle, itemsGap, listStyle, textStyle } = useTextStyle();
        const [level1, level2, level3] = answers;
        const val1 = level1?.response?.value;
        const val2 = level2?.response?.value;

        const rowStyle = useMemo(
            () => ({
                ...answerStyle,
                rowGap: itemsGap
            }),
            [answerStyle, itemsGap]
        );

        const handleChange = useCallback(
            ({ response: { change, changeExtra } }: ISurveyQuestionAnswerModel, level: number) =>
                (id: number | null) => {
                    const name = id === null ? null : residence.find(({ id: residenceId }) => residenceId === id).name;

                    switch (level) {
                        case 1:
                            level2?.response?.change(null);
                            level2?.response?.changeExtra(null);
                            level3?.response?.change(null);
                            level3?.response?.changeExtra(null);
                            break;
                        case 2:
                            level3?.response?.changeExtra(null);
                            level3?.response?.change(null);
                            break;
                        default:
                            if (level < 3) getResidences(level + 1, id);
                    }

                    change(id);
                    changeExtra(name);
                },
            [getResidences, level2?.response, level3?.response, residence]
        );

        const createOptions = useCallback(
            (lvl) => {
                const parent = lvl > 0 ? answers[lvl - 1] : null;

                return residence
                    .filter(
                        ({ level, parent: p }) => level === lvl + 1 && (parent ? parent?.response?.value === p : true)
                    )
                    .map(({ id, name }) => ({ label: name, value: id }));
            },
            [answers, residence]
        );

        useEffect(() => {
            getResidences(1, 0);

            if (val1) {
                getResidences(2, val1);
            }

            if (val2) {
                getResidences(3, val2);
            }
        }, [getResidences, val1, val2]);

        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.RESIDENCE, css.list)} style={listStyle}>
                {answers.map((answer, idx) => (
                    <div className={cn(CssPageBlock.ANSWER, css.answer)} key={idx} style={rowStyle}>
                        <div className={cn(CssUiComponent.LABEL, css.label)} style={textStyle}>
                            {RESIDENCE_TYPE_LABEL[answer.value[0] as string]}
                        </div>
                        <div className={css.field}>
                            <StyledDropdown
                                searchable
                                className={css.control}
                                controlClassName={css.control}
                                options={createOptions(idx)}
                                placeholder={RESIDENCE_TYPE_LABEL[answer.value[0] as string]}
                                value={answer?.response.value as number}
                                onChange={handleChange(answer, idx + 1)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    }
);
Residence.displayName = 'Residence';
